<template>
    <div class="business-area ptb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="business-content">
                        <h2>Enhanced Features of Gen-Decoder</h2>

                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-business-box">
                                    <h3>Longevity-focused Genetic Profiling</h3>
                                    <p>Our advanced algorithms are fine-tuned to identify genetic variations most relevant to aging and lifespan.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-business-box">
                                    <h3>Health Risk Assessment</h3>
                                    <p>Evaluate genetic predispositions for age-related diseases, allowing for early intervention strategies.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-business-box">
                                    <h3>Lifestyle and Wellness Recommendations</h3>
                                    <p>Based on genetic makeup, receive tailored advice on lifestyle changes and wellness practices that promote healthy aging.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-business-box">
                                    <h3>Continuous Updates</h3>
                                    <p>As research evolves, your genetic profile is updated to include the latest findings in the field of genomics and longevity.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image">
                        <img src="../../assets/images/dna2.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Business'
    }
</script>

<style scoped>
.image img { 
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Remove any extra space below the image */
    margin: 0 auto; /* Center the image horizontally within its container */
    padding-left: 80px;
}
</style>