<template>
    <div class="ctp-currency-area pb-100">
        <div class="container">
            <div class="row justify-content-center">
                

                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-content">
                        <h3>Unique Integration of Genetic and Epigenetic Insights</h3>
                        <p>Epi-Tracker uniquely combines genetic and epigenetic markers to offer a dynamic, personalized view of health risks that adapt to lifestyle and environmental changes. This integrated approach not only highlights genetic predispositions but also how these can be modified by epigenetic factors, providing actionable insights for proactive health management.</p>
                    
                        <ul class="list">
                            <li> 
                                <img src="../../assets/images/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Dynamic Risk Assessment
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Personalized Health Insights
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Proactive Management
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Actionable Outcomes
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-image"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Currency'
}
</script>

<style scoped>
.ctp-currency-image {
    background-image: url('@/assets/images/methy2.png');
    height: 60%;
    width: 100%;
    margin-top: 150px;
}
</style>