<template>
    <div>
        <SideBar />
        <SampleReportViewer />
    </div>
</template>

<script>
import SideBar from '../dashboard/SideBar.vue';
import SampleReportViewer from '../sample-report-viewer/SampleReportViewer.vue';

export default {
    name: 'SampleReports',
    components: {
        SideBar,
        SampleReportViewer
    }
}

</script>