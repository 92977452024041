<template>
    <div class="payment-features-area ptb-70">
        <div class="container">
            <div class="payment-features-overview">
                <div class="payment-features-content">
                    <div class="content">
                        <h2>Data Analysis Workflow</h2>
                        <p>In the first stage of our genetic data analysis, raw FASTQ files, containing the initial output from next-generation sequencing, are processed and aligned. This stage involves converting the raw sequences into a more interpretable format by aligning them against a reference genome. The result is a BAM file, which organizes the DNA sequences based on their specific locations in the human genome. This step is crucial for accurately identifying the genomic context of each sequence, enabling precise variant detection in subsequent analyses.</p>
                        <p>Following alignment, the next step is variant calling, where genetic variations are identified from the data provided in the BAM files. Using advanced algorithms, this process detects mutations such as single nucleotide polymorphisms (SNPs), insertions, and deletions within the genome. The identified variants are then compiled into a Variant Call Format (VCF) file, which includes detailed information about each variant, such as its genomic position, the nature of the mutation, and the quality of the data supporting the variant's identification.</p>
                        <p>The final step involves the interpretation of the variants listed in the VCF file. This analytical phase deciphers the biological and clinical significance of each genetic variation, assessing its potential impact on longevity and age-related health conditions. The interpreted data is synthesized into comprehensive reports, providing actionable insights and recommendations. These reports guide healthcare providers in developing personalized health strategies tailored to the genetic predispositions associated with aging, enhancing patient care and preventive health management.</p>
                        <router-link to="/login" class="link-btn">
                            Get started now
                        </router-link>
                    </div>
                </div>
                <div class="payment-features-content">
                    <div class="content">
                        <h2>The Science Behind Gen-Decoder</h2>
                        <p>In the realm of precision medicine, the analysis of DNA data stands as a cornerstone for uncovering individual predispositions to various health conditions and longevity. At Quantum Genomics, this process begins with the meticulous preprocessing of DNA data, which is pivotal for ensuring the accuracy and utility of the genetic information obtained. The initial quality control phase screens for any inconsistencies or corruption in the data, ensuring that subsequent analyses are based on reliable and high-quality genetic sequences. This is followed by the critical task of reads mapping, where short DNA sequences are aligned with a reference genome. This alignment is essential for accurately locating genetic variants, which are differences in DNA sequence among individuals.</p>
                        <p>These variants are then subjected to sophisticated variant calling techniques, where the presence of specific alleles (alternative forms of a gene) is identified. Each variant can influence biological processes in distinct ways, potentially increasing or decreasing the risk of certain diseases, affecting metabolic pathways, or impacting the aging process. The identified variants are rigorously filtered to exclude any that do not meet stringent confidence thresholds, ensuring that only the most reliable data is used for further analysis.</p>
                        <p>The culmination of this process is the generation of a detailed genetic profile that highlights over 35 million genetic variants. This profile forms the basis for the next crucial phase—analyzing health and longevity predispositions. By leveraging our extensive database and alignment with medically validated datasets such as the UK Biobank, Quantum Genomics can interpret how specific genetic variants correlate with health risks and longevity. For example, certain variants may be linked to an increased likelihood of cardiovascular diseases, diabetes, or neurodegenerative conditions, while others might be associated with more robust aging processes and extended healthspan. This deep analysis allows individuals to receive personalized insights and recommendations. By understanding these genetic predispositions, individuals can proactively manage their health through tailored lifestyle adjustments and preventive measures, ultimately guiding them towards a healthier and potentially longer life.</p>
                        <router-link to="/login" class="link-btn">
                            Get started now
                        </router-link>
                    </div>
                </div>
                <div class="payment-features-image">
                    <!-- <div class="image">
                        <img src="../../assets/images/dna4.png" alt="image">
                    </div> -->
                </div>
            </div>

            <div class="payment-features-overview">
                <!-- <div class="payment-features-content">
                    <div class="content">
                        <h2>The Science Behind Quantum Genomics</h2>
                        <p>Quantum Genomics builds on cutting-edge genetic research and technology to analyze DNA with a focus on longevity. We use whole genome sequencing (WGS) to decode DNA efficiently, combined with a powerful bioinformatics platform that integrates genetic data with clinical insights. This comprehensive approach highlights genetic markers linked to longevity, providing a deeper understanding of how these factors contribute to aging and health.</p>
                        <router-link to="/contact" class="link-btn">
                            Get started now
                        </router-link>
                    </div>
                </div> -->

                <div class="payment-features-image">
                    <!-- <div class="image">
                        <img src="../../assets/images/dna1.jpg" alt="image">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Payment'
    }
</script>
