<template>
    <div>
        <NavbarStyleThree />
        <MainBanner />
        <Featured id="features" />
        <div style="margin-top: 150px;"> <!-- Adjust the space as needed -->
            <HowItWork class="workpath" />
        </div>
        <Features />
        <GuidanceVideo />
        <Map />
        <div style="margin-top: 100px;"> <!-- Adjust the space as needed -->
            <Partner />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleThree from '../layout/NavbarStyleThree'
import MainBanner from '../mainpage/MainBanner'
import HowItWork from '../mainpage/HowItWork'
import Partner from '../mainpage/Partner'
import Services from '../mainpage/Services'
import Featured from '../mainpage/Featured'
import ServicesTwo from '../mainpage/ServicesTwo'
import ServicesThree from '../mainpage/ServicesThree'
import FeaturesTwo from '../mainpage/FeaturesTwo'
import Invoicing from '../mainpage/Invoicing'
import FunFacts from '../mainpage/FunFacts'
import Feedback from '../mainpage/Feedback'
import AccountCreate from '../mainpage/AccountCreate'
import Footer from '../layout/Footer'
import Map from '../mainpage/Map.vue'
import Features from '../mainpage/Features.vue'
import GuidanceVideo from '../mainpage/GuidanceVideo.vue'

export default {
    name: 'MainPage',
    components: {
        NavbarStyleThree,
        MainBanner,
        Featured,
        HowItWork,
        Services,
        ServicesTwo,
        ServicesThree,
        FeaturesTwo,
        Invoicing,
        FunFacts,
        Feedback,
        Partner,
        AccountCreate,
        Footer,
        Map,
        Features,
        GuidanceVideo
    }
}
</script>

<style scoped>
/* Media query for phones */
@media (max-width: 600px) {
    .workpath {
        display: none;
    }
}
</style>
