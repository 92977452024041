<template>
    <div class="page-title-area item-bg3">
        <div class="container">
            <div class="page-title-content">
                <h2>{{ title }}</h2>
                <p>{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitleComingSoon',
    props: {
        title: {
            type: String,
            default: 'Feature'
        },
        message: {
            type: String,
            default: 'This is a new feature.'
        }
    }
}
</script>