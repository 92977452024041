<template>
    <div class="section-title ctp-title">
        <h2>Our Publications</h2>
    </div>
    <div class="blog-area ptb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6" v-for="(post, index) in posts1" :key="index">
                    <div class="single-blog-post">
                        <div class="blog-image">
                            <img :src="post.image" alt="image">
                        </div>

                        <div class="blog-post-content">
                            <h3>{{ post.title }}</h3>

                            <span>By <a :href="post.authorLink">{{ post.author }}</a></span>

                            <p>{{ post.content }}</p>

                            <a :href="post.link" class="read-more-btn">
                                Read More <i class="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <!-- <div v-if="currentPage === 2" class="col-lg-4 col-md-6" v-for="(post, index) in posts2" :key="index">
                    <div class="single-blog-post">
                        <div class="blog-image">
                            <img :src="post.image" alt="image">
                        </div>

                        <div class="blog-post-content">
                            <h3><router-link to="/">{{ post.title }}</router-link></h3>

                            <span>By <a :href="post.authorLink">{{ post.author }}</a></span>

                            <p>{{ post.content }}</p>

                            <a :href="post.link" class="read-more-btn">
                                Read More <i class="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <a href="#" class="prev page-numbers" @click.prevent="changePage(currentPage - 1)"><i class="fas fa-angle-double-left"></i></a>
                        <span class="page-numbers" :class="{ current: currentPage === 1 }" @click="changePage(1)">1</span>
                        <a href="#" class="next page-numbers" @click.prevent="changePage(currentPage + 1)"><i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Publications',
        data() {
            return {
                currentPage: 1,
                posts1: [
                    {
                        image: require('@/assets/images/publications/nature.png'),
                        title: 'A common nonsense mutation results in α-actinin-3 deficiency in the general population',
                        author: 'Kathryn N. North, Nan Yang, Duangrurdee Wattanasirichaigoon, Michelle Mills, Simon Easteal & Alan H. Beggs',
                        authorLink: 'https://www.nature.com/articles/ng0499_353',
                        content: 'The study shows that α-actinin-3 deficiency, typical in fast-twitch muscle fibers, is common and not directly associated with muscle weakness across various neuromuscular conditions. Genetic tests in a family revealed ACTN3 mutations, including a stop-codon mutation, also present in healthy individuals, indicating a complex role of this gene in muscle function.',
                        link: 'https://www.nature.com/articles/ng0499_353'
                    },
                    {
                        image: require('@/assets/images/publications/molecular.png'),
                        title: 'Analysis of the ACTN3 heterozygous genotype suggests that α-actinin-3 controls sarcomeric composition and muscle function in a dose-dependent fashion',
                        author: 'Marshall W. Hogarth, Fleur C. Garton, ..., Nan Yang, Stewart I. Head, Kathryn N. North',
                        authorLink: 'https://academic.oup.com/hmg/article/25/5/866/2384523',
                        content: 'The R577X polymorphism in ACTN3, affecting about 18% of people, alters sprint and power performance due to a dose-dependent reduction of α-actinin-3. Studies in heterozygous mice reveal changes in muscle proteins and a shift towards oxidative metabolism, suggesting that ACTN3 levels influence muscle function significantly.',
                        link: 'https://academic.oup.com/hmg/article/25/5/866/2384523'
                    },
                    {
                        image: require('@/assets/images/publications/Lancet.png'),
                        title: 'Effects of putative metformin targets on phenotypic age and leukocyte telomere length: a mendelian randomisation study using data from the UK Biobank',
                        author: 'Shan Luo, Ian Chi Kei Wong, Celine Sze, Ling Chui, Jie Zheng, Yuan Huang, Catherine Mary Schooling, Shiu Lun Au Yeung',
                        authorLink: 'https://www.sciencedirect.com/science/article/pii/S2666756823000855',
                        content: 'Metformin use and genetically predicted HbA1c lowering are associated with younger phenotypic age, suggesting potential anti-aging effects, though effects on leukocyte telomere length are less consistent.',
                        link: 'https://www.sciencedirect.com/science/article/pii/S2666756823000855'
                    },
                    {
                        image: require('@/assets/images/publications/AJHG.jpg'),
                        title: 'The Effect of ACTN3 Gene Doping on Skeletal Muscle Performance',
                        author: 'Garton, F.C., Houweling, P.J., Vukcevic, D., Meehan, L.R., Lee, F.X., Lek, M., Roeszler, K.N., Hogarth, M.W., Tiong, C.F., Zannino, D. and Yang, N.',
                        authorLink: 'https://www.cell.com/ajhg/fulltext/S0002-9297(18)30098-3?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS0002929718300983%3Fshowall%3Dtrue',
                        content: 'The study indicates that ACTN3 gene overexpression in muscle does not enhance performance and can be harmful at high doses, underscoring its role in muscle metabolism rather than mass increase, with implications for gene doping and neuromuscular therapies.',
                        link: 'https://www.cell.com/ajhg/fulltext/S0002-9297(18)30098-3?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS0002929718300983%3Fshowall%3Dtrue'
                    },
                    {
                        image: require('@/assets/images/publications/AJHG.jpg'),
                        title: 'ACTN3 genotype is associated with human elite athletic performance',
                        author: 'Nan Yang, Daniel G. MacArthur, Jason P. Gulbin, Allan G. Hahn, Alan H. Beggs, Simon Easteal, Kathryn North',
                        authorLink: 'https://www.cell.com/ajhg/fulltext/S0002-9297(07)62024-2?cc=y%3D',
                        content: 'The ACTN3 gene R577X polymorphism is linked to athletic performance, influencing muscle force and speed differently in male and female athletes, suggesting evolutionary advantages for certain genetic traits in sprinting.',
                        link: 'https://www.cell.com/ajhg/fulltext/S0002-9297(07)62024-2?cc=y%3D'
                    },
                    {
                        image: require('@/assets/images/publications/cell.jpg'),
                        title: 'Epigenetic Memory Underlies Cell-Autonomous Heterogeneous Behavior of Hematopoietic Stem Cells',
                        author: 'WC Vionnie, Rushdia Z Yusuf, Toshihiko Oki, Juwell Wu, Borja Saez, Xin Wang, ..., David T Scadden',
                        authorLink: 'https://www.cell.com/cell/fulltext/S0092-8674(16)31466-0',
                        content: 'Research on hematopoietic stem cells (HSCs) shows that clone-specific epigenetic profiles, rather than transcriptional activity, predominantly dictate HSC behavior, suggesting a revision of stem cell plasticity and niche concepts.',
                        link: 'https://www.cell.com/cell/fulltext/S0092-8674(16)31466-0'
                    }
                ],
                posts2: [
                    // {
                    //     image: '../../assets/images/placeholder.png',
                    //     title: 'Placeholder Title 1',
                    //     author: 'Placeholder Author',
                    //     authorLink: '#',
                    //     content: 'Placeholder content for the first blank item.',
                    //     link: '#'
                    // },
                    // {
                    //     image: '../../assets/images/placeholder.png',
                    //     title: 'Placeholder Title 2',
                    //     author: 'Placeholder Author',
                    //     authorLink: '#',
                    //     content: 'Placeholder content for the second blank item.',
                    //     link: '#'
                    // },
                    // {
                    //     image: '../../assets/images/placeholder.png',
                    //     title: 'Placeholder Title 3',
                    //     author: 'Placeholder Author',
                    //     authorLink: '#',
                    //     content: 'Placeholder content for the third blank item.',
                    //     link: '#'
                    // },
                    // {
                    //     image: '../../assets/images/placeholder.png',
                    //     title: 'Placeholder Title 4',
                    //     author: 'Placeholder Author',
                    //     authorLink: '#',
                    //     content: 'Placeholder content for the fourth blank item.',
                    //     link: '#'
                    // }
                ]
            };
        },
        methods: {
            changePage(page) {
                if (page >= 1 && page <= 2) {
                    this.currentPage = page;
                }
            }
        }
    };
</script>

<style scoped>
.blog-image{
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-image img {
    /* height: 200px; Adjust the height as needed */
    padding-top: 30px;
    max-height: 200px;
    width: 80%;
    object-fit: cover; /* Ensures the image covers the area without distortion */
}

.blog-post-content {
    margin-top: -20px;
}

/* .single-blog-post {
    height: 700px;
} */
</style>