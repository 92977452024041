<template>
    <div class="services-area ptb-70">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-content">
                    <div class="content left-content">
                        <h2>Large or enterprise level businesses</h2>
                        <div class="bar"></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>

                        <ul class="services-list">
                            <li><span><i class="flaticon-check-mark"></i> Corporate Cards</span></li>
                            <li><span><i class="flaticon-check-mark"></i> International Payments</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Automated accounting</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Request Features</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Premium Support</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Direct Debit</span></li>
                        </ul>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/images/businesses-3.png" alt="image">

                        <div class="circle-img">
                            <img src="../../assets/images/circle.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServicesThree'
    }
</script>