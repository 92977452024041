<template>
  <div class="workpath">    
    <div class="patharea">
      <img src="@/assets/images/workpath/Path-01.png" alt="workpath"/>
      <!-- Nodes -->
      <div
        v-for="(node, index) in nodes"
        :key="index"
        :class="['node', `node-${index}`]"
        @mouseover="showInfo(index)"
        @mouseleave="resetInfoState()"
      >
        <img :src="node.src" alt="node-img" class="node-img" >
      </div>
    </div>
    
    <div 
      v-for="(tick, index) in ticks"
      :key="index"
      :class="[`tick-${index}`, 'tick']"
      @mouseover="showInfo(index)"
      @mouseleave="resetInfoState()"
    >
      <div class="tick-title">
        <div class="icon">
          <iconify-icon class="iconify" :icon="tick.icon"></iconify-icon>
        </div>
        <h3>{{ tick.title }}</h3>
      </div>
      <p>{{ tick.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nodes: [
        { name: "Blood DNA Extraction (Optional)", description: "This is a start point", src: require("@/assets/images/workpath/m1.png")},
        { name: "Next-Generation Sequencing or methylation microarray (Optional)", description: "Description for the first node.", src: require("@/assets/images/workpath/m2.png")},
        { name: "Bioinformatic Analysis", description: "Description for the second node.",  src: require("@/assets/images/workpath/m3.png")},
        { name: "Predisposition Report", description: "Description for the third node.",  src: require("@/assets/images/workpath/m4.png")},
        { name: "Fourth Node", description: "Description for the fourth node.",  src: require("@/assets/images/workpath/m5.png")},
      ],
      ticks: [
        { title: "Blood DNA Extraction (Optional)", description: "Send your blood samples for DNA extraction to our certified partners if required.", color: "#cccccc", icon: "mdi:number-1-circle-outline" },
        { title: "NGS or methylation EPIC (Optional)", description: "Send your DNA samples for library preparation and sequencing or microarray analysis through our certified provider if required.", color: "#8e44ad", icon: "mdi:number-2-circle-outline" },
        { title: "Bioinformatic Analysis", description: "Submit your raw data (FastQ or IDAT) or preprocessed files (BAM/VCF or Beta-value) for our bioinformaticians to conduct variant calling or quantitative methylation analysis.", color: "#2980b9", icon: "mdi:number-3-circle-outline" },
        { title: "Predisposition Report", description: "Receive a tailored report on your genomic predispositions, crafted using our expertise and AI-powered model to analyze the key health and longevity loci.", color: "#f1c40f", icon: "mdi:number-4-circle-outline" },
        { title: "Genetics Consulting", description: "Receive expert guidance from our clinical geneticists and multidisciplinary team to utilize your genomic insights for personalized lifestyle changes.", color: "#27ae60", icon: "mdi:number-5-circle-outline" },
      ],
    };
  },
  mounted() {
    this.updateNodeSize();
    window.addEventListener("resize", this.updateNodeSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateNodeSize);
  },
  methods: {
    updateNodeSize(){
      const nodes = this.$el.querySelectorAll(".node");
      const patharea = this.$el.querySelector(".patharea");
      let width = patharea.clientWidth;
      let nodes_size = width * 0.05;
      nodes.forEach((node) => {
        node.style.width = `${nodes_size}px`;
        node.style.height = `${nodes_size}px`;
      });
    },

    showInfo(index) {
      this.resetInfoState();
      const hoveredNode = this.$el.querySelector(`.node-${index}`);
      if (hoveredNode) {
        hoveredNode.classList.add("active");
      }
      const hoveredTick = this.$el.querySelector(`.tick-${index}`);
      if (hoveredTick) {
        // hoveredTick.style.display = "block";
        setTimeout(() => {
          hoveredTick.classList.add("active");
        }, 10);
      }
      else {
        console.log("No tick found");
      }
    },

    resetInfoState() {
      // const hoveredNode = this.$el.querySelector(`.node-${index}`);
      // if (hoveredNode) {
      //   hoveredNode.classList.remove("active");
      // }
      // const hoveredTick = this.$el.querySelector(`.tick-${index}`);
      // if (hoveredTick) {
      //   hoveredTick.classList.add("inactive");
      //   hoveredTick.classList.remove("active");
      //   // setTimeout(() => {
      //   //   hoveredTick.style.display = "none";
      //   // }, 200);
      // }
      const nodes = this.$el.querySelectorAll(".node");
      const ticks = this.$el.querySelectorAll(".tick");
      nodes.forEach((node) => {
        node.classList.remove("active");
      });
      ticks.forEach((tick) => {
        tick.classList.remove("active");
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/dashboard.scss";
$main-color: #2102d1;
$white-color: #ffffff;
.workpath {
  position: relative;
  width: 100%;
  height: 100%;

  .patharea {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      transform: translateY(-10%) translateX(2%);
      z-index: 1;
    }

    %node {
      position: absolute;
      cursor: pointer;
      transition: background-color 0.5s ease;
      z-index: 3;

      &.active {
        transform: scale(1.2) translateY(-10px);
        transition: transform 0.5s ease;
      }

      &.node-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .node-0 {
        @extend %node;
        top: 27%;
        left: 10%;
      }

    .node-1 {
      @extend %node;
      top: 30%;
      left: 40%;
    }

    .node-2 {
      @extend %node;
      top: 49%;
      left: 35%;
    }

    .node-3 {
      @extend %node;
      top: 54%;
      left: 65%;
    }

    .node-4 {
      @extend %node;
      top: 58%;
      left: 90%;
    }

    
  }

  %tick {
    position: absolute;
    z-index: 1;
    display: block;
    transition: all 0.1s ease;
    width: 28%;
    background-color: #fafafa;
    border-radius: 10px;
    box-shadow: 0 15px 16px #0000000d;
    padding: 20px;

    .tick-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      

      .icon {
        width: 50px;
        height: 50px;
        align-items: center;
        display: flex;
        justify-content: center;
        

        .iconify {
          font-size: 50px;
          color: $main-color;
        }
      }

      h3 {
        font: {
            size: 22px;
            weight: 500;
        };
        display: flex;
        transition: .4s;
        margin-left: 8px;
      }
    }

    p {
      font: {
          weight: 300;
      };
    }

    &.active {
        transform: translateY(-10px) scale(1.1);
        background-color: #E5F6F0;
        transition: background-color 0.5s ease;
        

        &::before {
            width: 100%;
        }
        .tick-title {
          .icon {
            .iconify {
              transform: rotate(360deg);
              transition: .4s;
            }
          }

          h3 {
            font: {
                weight: 600;
            }
          }
        }

        p {
          font: {
              weight: 400;
          };
        }
    }

  }

  .tick-0 {
    @extend %tick;
    top: 0%;
    left: 15%;
  }

  .tick-1 {
    @extend %tick;
    top: 0%;
    right: 15%;  
  }

  .tick-2 {
    @extend %tick;
    bottom: 0%;
    left: 2%;
  } 

  .tick-3 {
    @extend %tick;
    bottom: 0%;
    left: 37%;
  }

  .tick-4 {
    @extend %tick;
    bottom: 0%;
    left: 70%;
  }
}
</style>
