<template>
    <div class="support-area ptb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="image">
                        <img src="../../assets/images/hourglass.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="support-content">
                        <h2>What is Epi-Insight?</h2>
                        <p>Epi-Insight is our pioneering service that transforms intricate DNA methylation data into clear, actionable insights on epigenetic aging. Our platform skillfully combines advanced genomic technologies with sophisticated bioinformatics to analyze aging at a molecular level, offering detailed reports on epigenetic age, the pace of aging, and organ-specific aging patterns. Designed for both medical centres and clinicians, Epi-Insight serves as a crucial tool in unraveling the complexities of aging, helping users make informed decisions that could potentially influence health outcomes and longevity.</p>
                        <!-- <router-link to="/Login" class="btn btn-primary">
                            Find Out More
                        </router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Support'
    }
</script>

<style scoped>
.image img { 
    width: 50%;
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Remove any extra space below the image */
    margin: 0 auto; /* Center the image horizontally within its container */
    padding-left: 20px;
    margin-right: 80px;
}
</style>