<template>
  <div>
    <SideBar />
    <CnyAdminArea />
  </div>
</template>

<script>
import SideBar from "../dashboard/SideBar.vue";
import CnyAdminArea from "../admin/CnyAdminArea";

export default {
  name: "CnyAdminPage",
  components: {
    SideBar,
    CnyAdminArea,
  },
};
</script>
