<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Quantum Life Terms of Service</h2>
                <div class="bar"></div>
                <p>INFORMATION & NOTICES</p>
            </div>

            <div class="privacy-policy-content">
                <h3>1. Introduction</h3>
                <p>Welcome to Quantum Life Limited (together with its affiliates ("we", "us", "our", or “Quantum Life”)). By accessing our website, mobile application, or any of our services (collectively, the "Services"), you agree to be bound by these Terms of Service ("Terms"). These Terms govern your access to and use of our Services and any consultations, information, or other services provided through our platform. We may amend these Terms at any time and for any reason. The updated version will be available by following the “Terms of Service” link on our website homepage. You should check the Terms regularly for changes.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>2. Privacy and Personal Information</h3>
                <p>We are committed to protecting your privacy. Our Privacy Policy details how we collect, use, and disclose information about you. By using our Services, you agree to our collection, use, and disclosure of your information as outlined in our Privacy Policy, including the transfer of this information to other countries for storage, processing, and use by Quantum Life and its affiliates.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>3. Services</h3>
                <ul>
                    <li><strong>Consultations</strong>: Our platform provides access to medical professionals for informational and consultation purposes only. The advice provided is not a substitute for professional medical advice, diagnosis, or treatment.</li>
                    <li><strong>Emergency Situations</strong>: Our Services are not for medical emergencies. In case of an emergency, contact your local emergency services immediately.</li>
                </ul>
            </div>

            <div class="privacy-policy-content">
                <h3>4. User Responsibilities</h3>

                <ul>
                    <li><strong>Security</strong>: You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account.</li>
                    <li><strong>Accurate Information</strong>: You agree to provide accurate, current, and complete information about yourself and your health conditions for any consultations.</li>
                    <li><strong>Compliance with Laws</strong>: You agree to comply with all applicable laws, including privacy laws and regulations regarding the control and use of personal data.</li>
                </ul>
            </div>

            <div class="privacy-policy-content">
                <h3>5. Intellectual Property</h3>
                <p>All content, trademarks, logos, and service marks displayed on our Services are our property or the property of other third parties. You are not permitted to use any of these without our prior written consent or the consent of such third party.
                </p>
            </div>

            <div class="privacy-policy-content">
                <h3>6. Disclaimers</h3>
                <p>We do not guarantee the accuracy, completeness, or usefulness of any information on the Service and we neither endorse nor are responsible for the accuracy and reliability of any opinion, advice, or statement made. The Services are provided on an "as is" and "as available" basis.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>7. Limitation of Liability</h3>
                <p>To the fullest extent permitted by applicable law, neither Quantum Life Limited nor its affiliates, nor any of its or their officers, directors, employees, contractors, agents, or affiliates will be liable for any losses (whether direct, indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other  losses of any type, resulting from your access to or use of or inability to access or use the Services.
                </p>
            </div>

            <div class="privacy-policy-content">
                <h3>8. Indemnification</h3>
                <p>You irrevocably undertake to defend, fully indemnify, and hold harmless Quantum Life Limited and its affiliates, and its and their officers, directors, employees, contractors and agents, from and against any and all claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including full attorneys' fees) arising out of or relating to your breach of these Terms and/or your use of the Services, including, but not limited to, your submissions, third-party sites, any use of the Services or any of Quantum Life’s content, services, and products other than as expressly authorized in these Terms.
                </p>
            </div>

            <div class="privacy-policy-content">
                <h3>9. Modification and Termination</h3>
                <p>We reserve the right to modify or discontinue, temporarily or permanently, the Services (or any part thereof) with or without notice. We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation any breach of these Terms.
                </p>
            </div>

            <div class="privacy-policy-content">
                <h3>10. Governing Law</h3>
                <p>These Terms shall be governed and construed in accordance with the laws of Hong Kong S.A.R., without regard to conflict of law provisions.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>11.Dispute Resolution</h3>
                <p>Any dispute, controversy, difference or claim arising out of or relating to these Terms, including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to it shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre (HKIAC) under the HKIAC Administered Arbitration Rules in force when the Notice of Arbitration is submitted. The law of this arbitration clause shall be Hong Kong law. The seat of arbitration shall be Hong Kong. The number of arbitrators shall be one, to be appointed by Quantum Life Limited. The arbitration proceedings shall be conducted in English.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TermsOfServiceArea'
    }
</script>