export const accountRoles = {
    admin: [ 'admin' ],
    lab: [ 'Laboratory' ],
    user: [
        'Medical Facility (Hospital, Clinic, etc.)',
        'Research Institution (University, Research Institute)',
        'Pharmaceutical Company',
        'Biotech Company',
        'Healthcare Technology Company',
        'Public Health Organization',
        'Government Agency',
        'IT Company',
    ],
};