<template>
  <div v-if="isAdmin">
    <div class="title-wrapper">
      <div class="page-title-content-submit">
        <h2 class="title is-bold">QuantumLife Administration Page</h2>
        <p class="light-text">Manage the user accounts here.</p>
      </div>
    </div>

    <!-- Popup for Alerts and Completion Messages -->
    <div v-if="showPopup" class="popup-overlay" @click.self="closePopup">
      <div :class="['popup', popupType]">
        <button class="close" @click="closePopup">&times;</button>
        <p>{{ popupMessage }}</p>
      </div>
    </div>

    <div class="user-container">
      <!-- List users-->
      <div class="multiselect">
        <div
          class="multiselect-wrapper"
          tabindex="0"
          role="combobox"
          @click.stop="toggleDropdown('user')"
        >
          <div class="multiselect-placeholder" v-if="!selectedUser">User</div>
          <div class="multiselect-single-label" v-else>
            <div class="multiselect-single-label-text">
              {{ selectedUser.email }}
            </div>
          </div>
          <div class="multiselect-caret">
            <iconify-icon
              class="iconify"
              icon="lucide:chevron-down"
            ></iconify-icon>
          </div>
        </div>
        <div class="multiselect-dropdown is-hidden" tabindex="1" id="user">
          <input
            type="text"
            v-model="searchTerm"
            placeholder="Search users on email..."
            class="multiselect-search"
          />
          <ul
            class="multiselect-options"
            id="multiselect-options"
            role="listbox"
          >
            <div
              class="multiselect-option"
              role="option"
              v-for="u in filteredUserList"
              :key="u"
              @click.stop="handleSelectUser(u)"
              :class="{ 'is-selected': selectedUser === u }"
            >
              <span>{{ u.email + " - " + u.companyName }}</span>
            </div>
          </ul>
        </div>
      </div>

      <div v-if="selectedUser">
        <!-- User information -->
        <div>{{ selectedUser.firstname + " " + selectedUser.lastname }}</div>
        <div>{{ selectedUser.companyName }}</div>
        <div>{{ selectedUser.jobLevel + " " + selectedUser.jobFunction }}</div>
        <div>{{ selectedUser.phone }}</div>
        <div>{{ selectedUser.email }}</div>
        <!--Options-->

        <p>Change credits</p>
        User current credits :
        {{ selectedUser.credits ? selectedUser.credits : 0 }}
        <p>
          <input type="text" id="credits" name="credits" />
          <button
            class="button v-button is-elevated is-rounded is-primary"
            @click="changeCredits('+')"
          >
            Add
          </button>
          <button
            class="button v-button is-elevated is-rounded is-primary"
            @click="changeCredits('-')"
          >
            Remove
          </button>
        </p>

        <p>Change user status</p>
        User current status :
        {{ selectedUser.status ? selectedUser.status : "New" }}
        <div class="multiselect">
          <div
            class="multiselect-wrapper"
            tabindex="0"
            role="combobox"
            @click.stop="toggleDropdown('status')"
          >
            <div class="multiselect-placeholder" v-if="!selectedStatus">
              Status
            </div>
            <div class="multiselect-single-label" v-else>
              <div class="multiselect-single-label-text">
                {{ selectedStatus }}
              </div>
            </div>
            <div class="multiselect-caret">
              <iconify-icon
                class="iconify"
                icon="lucide:chevron-down"
              ></iconify-icon>
            </div>
          </div>
          <div class="multiselect-dropdown is-hidden" tabindex="1" id="status">
            <ul
              class="multiselect-options"
              id="multiselect-options"
              role="listbox"
            >
              <div
                class="multiselect-option"
                role="option"
                v-for="u in statusList"
                :key="u"
                @click.stop="handleSelectStatus(u)"
                :class="{ 'is-selected': selectedStatus === u }"
              >
                <span>{{ u }}</span>
              </div>
            </ul>
          </div>
        </div>
        <button
          class="button v-button is-elevated is-rounded is-primary"
          @click="changeStatus()"
        >
          Change
        </button>
      </div>
    </div>
    <div class="form-group">
      <!-- Waiting list -->
      <div v-if="waitingList.length > 0" class="ql-admin-lists">
        <h3 class="title is-bold">Waiting list</h3>
        <div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Company</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(waitingUser, index) in paginatedWaitingList"
                :key="index"
              >
                <td>
                  <div class="text-wrap">
                    {{ waitingUser.email }}
                  </div>
                </td>
                <td>
                  <div class="text-wrap">{{ waitingUser.companyName }}</div>
                </td>

                <td>
                  <button
                    class="button v-button is-elevated is-rounded is-primary"
                    @click="activateUser(waitingUser.email)"
                  >
                    Set to Active
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination" v-if="totalPagesWait > 1">
          <button
            class="button"
            @click="prevPage('wait')"
            :disabled="currentPageWait === 1"
          >
            <
          </button>
          <span>Page {{ currentPageWait }} of {{ totalPagesWait }}</span>
          <button
            class="button"
            @click="nextPage('wait')"
            :disabled="currentPageWait === totalPagesWait"
          >
            >
          </button>
        </div>
      </div>

      <!-- Message list -->
      <div v-if="feedbackList.length > 0" class="ql-admin-lists">
        <h3 class="title is-bold">User messages</h3>
        <div>
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>Subject</th>
                <th>Message</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(feedback, index) in paginatedFeedbackList"
                :key="index"
              >
                <td>
                  <div class="text-wrap">
                    {{
                      feedback.email +
                      " (" +
                      feedback.firstname +
                      " " +
                      feedback.surname +
                      ")"
                    }}
                  </div>
                </td>
                <td>
                  <div class="text-wrap">{{ feedback.msg_subject }}</div>
                </td>

                <td>
                  <button
                    class="button v-button is-elevated is-rounded is-primary"
                    @click="openMessage(index)"
                  >
                    Open message
                  </button>
                </td>

                <td>
                  <button
                    class="button v-button is-elevated is-rounded is-primary"
                    @click="markAsRead(index)"
                  >
                    Mark as read
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination" v-if="totalPagesMsg > 1">
          <button
            class="button"
            @click="prevPage('msg')"
            :disabled="currentPageMsg === 1"
          >
            <
          </button>
          <span>Page {{ currentPageMsg }} of {{ totalPagesMsg }}</span>
          <button
            class="button"
            @click="nextPage('msg')"
            :disabled="currentPageMsg === totalPagesMsg"
          >
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { auth, db } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  collectionGroup,
  getDocs,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { useRouter } from "vue-router";
import {
  STATUS_LIST,
  USER_STATUS_ACTIVE,
  USER_STATUS_QL_ADMIN,
} from "../dashboard/dashboardConstants";

export default {
  name: "FileUpload",

  setup() {
    const user = ref(null);
    const userid = ref(null);
    const router = useRouter(); // Get the router instance
    const isAdmin = ref(false);

    const selectedUser = ref(null);

    const userList = ref([]);

    const selectedStatus = ref(null);

    const statusList = ref([]);

    const searchTerm = ref("");

    const waitingList = ref([]);

    const feedbackList = ref([]);

    const paginatedWaitingList = ref([]);

    const paginatedFeedbackList = ref([]);

    const currentPageMsg = ref(1);
    const totalPagesMsg = ref(0);
    const itemsPerPageMsg = ref(5);

    const currentPageWait = ref(1);
    const totalPagesWait = ref(0);
    const itemsPerPageWait = ref(5);

    // Fill the userList

    // Computed property for filtered user list
    const filteredUserList = computed(() => {
      return userList.value.filter((user) =>
        user.email.toLowerCase().includes(searchTerm.value.toLowerCase())
      );
    });

    // Method to handle user selection
    const handleSelectUser = (user) => {
      selectedUser.value = user;
      searchTerm.value = ""; // Clear search term on selection
      toggleDropdown("user"); // Assuming this is defined
    };

    // Fetch user list
    const getUserList = async () => {
      const userDocRefSnapshot = await getDocs(collection(db, "users"));
      let users = userDocRefSnapshot.docs.map((doc) => doc.data());
      users.sort((a, b) => a.email.localeCompare(b.email));
      userList.value = users;
    };

    // Fetch wait list
    const getWaitList = async () => {
      const waitlistDocRefSnapshot = await getDocs(collection(db, "waitlist"));
      let waitlist = waitlistDocRefSnapshot.docs.map((doc) => doc.data());
      waitlist.sort((a, b) => a.email.localeCompare(b.email));
      waitingList.value = waitlist;

      const start = (currentPageWait.value - 1) * itemsPerPageWait.value;

      totalPagesWait.value = Math.ceil(
        waitingList.value.length / itemsPerPageWait.value
      );

      paginatedWaitingList.value = waitingList.value.slice(
        start,
        start + itemsPerPageWait.value
      );
    };

    // Fetch feedback list
    const getFeedback = async () => {
      try {
        // Query the collection group named "feedbacks"
        const feedbackDocRefSnapshot = await getDocs(
          collectionGroup(db, "feedbacks")
        );

        // Map the documents to get the data and the document ID
        let feedbacks = feedbackDocRefSnapshot.docs.map((doc) => {
          const userId = doc.ref.parent.parent.id; // Get the user ID from the parent document
          const feedbackData = doc.data();
          return { ...feedbackData, userId }; // Combine the feedback data and user ID
        });

        for (let feedback of feedbacks) {
          3;
          if (feedback.read === true) {
            continue;
          }

          feedbackList.value.push(feedback);
        }

        const start = (currentPageMsg.value - 1) * itemsPerPageMsg.value;

        totalPagesMsg.value = Math.ceil(
          feedbackList.value.length / itemsPerPageMsg.value
        );

        paginatedFeedbackList.value = feedbackList.value.slice(
          start,
          start + itemsPerPageMsg.value
        );
      } catch (error) {
        console.error("Error fetching feedback:", error);
      }
    };

    // Fetch user list when component mounts
    onMounted(() => {
      getUserList();

      getWaitList();

      getFeedback();
    });

    statusList.value = STATUS_LIST;

    window.onbeforeunload = function () {};

    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        user.value = currentUser;
        userid.value = currentUser.uid;

        const userRef = doc(db, "users", userid.value);
        getDoc(userRef)
          .then((doc) => {
            if (doc.exists()) {
              const userProfile = doc.data();

              if (userProfile.status === USER_STATUS_QL_ADMIN) {
                isAdmin.value = true;
              } else {
                // redirect to dashboard
                router.push("/dashboard"); // Use this to access the Vue instance
              }
            } else {
              console.error("No such document!");
            }
          })
          .catch((error) => {
            console.error("Error getting document:", error);
          });
      } else {
        user.value = null;
        userid.value = null;
        showAlert("Please login to use data submission.");
      }
    });

    const changeCredits = (addOrSubtract) => {
      // Add credits for user

      const credits = document.getElementById("credits").value;

      if (isNaN(credits) || credits < 0) {
        showAlert("Please enter a positive numeric value.");
        return null;
      }

      let newCredits = 0;
      if (addOrSubtract === "+") {
        newCredits = selectedUser.value.credits
          ? Number(selectedUser.value.credits) + Number(credits)
          : Number(credits);
      } else {
        newCredits = selectedUser.value.credits
          ? Number(selectedUser.value.credits) - Number(credits)
          : 0;
        if (newCredits < 0) {
          newCredits = 0;
        }
      }

      try {
        const userRef = doc(db, "users", selectedUser.value.uid);
        setDoc(userRef, { credits: newCredits }, { merge: true }).catch(
          (error) => {
            console.error("Error changing credits: ", error);
            return null;
          }
        );
        popupMessage.value = `Credits modified successfully.`;
        popupType.value = "success";
        showPopup.value = true;

        selectedUser.value.credits = newCredits;

        // Refresh credits in sidebar

        if (selectedUser.value.uid === user.value.uid) {
          const event = new CustomEvent("creditsChanged", {
            detail: newCredits,
          });
          document.dispatchEvent(event);
        }
      } catch (error) {
        console.error("Error changing credits: ", error);
      }
    };

    const changeStatus = () => {
      if (!selectedStatus.value) {
        showAlert("Please select a status.");
        return null;
      }

      if (selectedUser.value.status === USER_STATUS_QL_ADMIN) {
        showAlert("You cannot change the status of a QL Admin.");
        return null;
      }

      try {
        const userRef = doc(db, "users", selectedUser.value.uid);
        setDoc(
          userRef,
          { status: selectedStatus.value },
          { merge: true }
        ).catch((error) => {
          console.error("Error changing status: ", error);
          return null;
        });
        popupMessage.value = `Status modified successfully.`;
        popupType.value = "success";
        showPopup.value = true;

        selectedUser.value.status = selectedStatus.value;
      } catch (error) {
        console.error("Error changing status: ", error);
      }
    };

    // Function to fetch user by email
    const getUserByEmail = async (email) => {
      const usersCollection = collection(db, "users"); // Replace with your actual collection name
      const q = query(usersCollection, where("email", "==", email)); // Create a query to find the user

      try {
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          console.log("No matching documents found.");
          return null; // No user found with that email
        }

        // Assuming each email is unique, you can return the first matching document
        let userData = null;
        querySnapshot.forEach((doc) => {
          userData = { id: doc.id, ...doc.data() }; // Include document ID
        });

        return userData; // Return the user data
      } catch (error) {
        console.error("Error getting documents: ", error);
        return null; // Handle errors appropriately
      }
    };

    // sets user to active, removes them from waiting list
    const activateUser = async (email) => {
      let user = await getUserByEmail(email);

      console.log(email);
      console.log("user", user);

      if (user && user.uid.length > 0) {
        // set user to active
        try {
          const userRef = doc(db, "users", user.uid);
          setDoc(
            userRef,
            { status: USER_STATUS_ACTIVE },
            { merge: true }
          ).catch((error) => {
            console.error("Error changing status: ", error);
            return null;
          });
        } catch (error) {
          console.error("Error changing status: ", error);
        }

        // remove from waiting list
        try {
          const waitlistRef = doc(db, "waitlist", user.uid);
          deleteDoc(waitlistRef).catch((error) => {
            console.error("Error changing status: ", error);
            return null;
          });
        } catch (error) {
          console.error("Error changing status: ", error);
        }

        waitingList.value = waitingList.value.filter(
          (item) => item.email != email
        );
      }
    };

    const showPopup = ref(false);
    const popupMessage = ref("");
    const popupType = ref("");

    const getIdToken = async () => {
      const firebaseUID = await user.value.getIdToken(/* forceRefresh */ true);
      return firebaseUID;
    };

    const showAlert = (message) => {
      popupMessage.value = message;
      popupType.value = "alert";
      showPopup.value = true;
    };

    const closePopup = () => {
      showPopup.value = false;
      //resetComponent();
    };

    const validateSelection = (selection) => {
      selectedUser.value = selection;
      console.log(selection.name + " has been selected");
    };

    const getDropdownValues = (keyword) => {
      console.log(
        "You could refresh options by querying the API with " + keyword
      );
    };

    const toggleDropdown = (id) => {
      const dropdown = document.getElementById(id);
      dropdown.classList.toggle("is-hidden");
      const dropdowns = document.querySelectorAll(".multiselect-dropdown");
      dropdowns.forEach((item) => {
        if (item.id !== id) {
          item.classList.add("is-hidden");
        }
      });
    };

    const handleSelectStatus = (value) => {
      selectedStatus.value = value;
      toggleDropdown("status");
    };

    const filterUserList = () => {
      return userList.value.filter((user) =>
        user.email.toLowerCase().includes(searchTerm.value.toLowerCase())
      );
    };

    const openMessage = (index) => {
      console.log(feedbackList.value[index].message);

      popupMessage.value = feedbackList.value[index].message;
      popupType.value = "success";
      showPopup.value = true;
    };

    const markAsRead = async (index) => {
      // delete from feedbackList the record that has the same created_at value
      feedbackList.value = feedbackList.value.filter(
        (item) => item.created_at != feedbackList.value[index].created_at
      );

      const start = (currentPageMsg.value - 1) * itemsPerPageMsg.value;

      totalPagesMsg.value = Math.ceil(
        feedbackList.value.length / itemsPerPageMsg.value
      );

      paginatedFeedbackList.value = feedbackList.value.slice(
        start,
        start + itemsPerPageMsg.value
      );

      // delete from feedback in firebase database

      const feedbackDocRefSnapshot = await getDocs(
        collectionGroup(db, "feedbacks")
      );

      // Map the documents to get the data and the document ID
      let feedbacks = feedbackDocRefSnapshot.docs.map((doc) => {
        const feedbackData = doc.data();
        return {
          ...feedbackData,
          id: doc.id,
          userId: doc.ref.parent.parent.id,
        }; // Add user ID
      });

      for (let feedback of feedbacks) {
        if (
          feedback.created_at.seconds ===
          feedbackList.value[index].created_at.seconds
        ) {
          console.log(feedback);

          const feedbackRef = doc(
            db,
            "feedbacks",
            feedbackList.value[index].userId,
            "feedbacks",
            feedback.id
          );

          console.log("feedbackList.value[index]", feedbackList.value[index]);

          // do not delete doc, just mark as read
          setDoc(feedbackRef, { read: true }, { merge: true }).catch(
            (error) => {
              console.error("Error changing status: ", error);
              return null;
            }
          );
        }
      }
    };

    const nextPage = (type) => {
      if (type === "wait") {
        if (currentPageWait.value < totalPagesWait.value) {
          currentPageWait.value++;

          const start = (currentPageWait.value - 1) * itemsPerPageWait.value;

          paginatedWaitingList.value = waitingList.value.slice(
            start,
            start + itemsPerPageWait.value
          );
        }
      }

      if (type === "msg") {
        if (currentPageMsg.value < totalPagesMsg.value) {
          currentPageMsg.value++;

          const start = (currentPageMsg.value - 1) * itemsPerPageMsg.value;

          paginatedFeedbackList.value = feedbackList.value.slice(
            start,
            start + itemsPerPageMsg.value
          );
        }
      }
    };

    const prevPage = (type) => {
      if (type === "wait") {
        if (currentPageWait.value > 1) {
          currentPageWait.value--;

          const start = (currentPageWait.value - 1) * itemsPerPageWait.value;

          paginatedWaitingList.value = waitingList.value.slice(
            start,
            start + itemsPerPageWait.value
          );
        }
      }
      if (type === "msg") {
        if (currentPageMsg.value > 1) {
          currentPageMsg.value--;

          const start = (currentPageMsg.value - 1) * itemsPerPageMsg.value;

          paginatedFeedbackList.value = feedbackList.value.slice(
            start,
            start + itemsPerPageMsg.value
          );
        }
      }
    };

    filterUserList();

    return {
      user,
      userid,

      showPopup,
      popupMessage,
      popupType,

      closePopup,

      isAdmin,
      validateSelection,
      getDropdownValues,

      toggleDropdown,

      userList,
      filteredUserList,
      handleSelectUser,
      selectedUser,
      searchTerm,

      handleSelectStatus,
      selectedStatus,
      statusList,

      changeCredits,
      changeStatus,

      waitingList,
      activateUser,

      feedbackList,
      openMessage,

      nextPage,
      prevPage,

      paginatedFeedbackList,
      paginatedWaitingList,
      currentPageMsg,
      totalPagesMsg,
      itemsPerPageMsg,

      currentPageWait,
      totalPagesWait,
      itemsPerPageWait,

      markAsRead,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;

  align-content: center;

  width: 50%;
  margin: auto;
}

.ql-admin-lists {
  width: 100%; /* Use full width */
  max-width: 800px; /* Set a max width for the history box */
  border: 2px dashed #ccc;
  border-radius: 10px; /* Rounded corners */
  padding: 10px; /* Padding inside the box */
  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Stack children vertically */
  height: 36rem; /* Allow height to adjust based on content */
  margin: 0 auto; /* Center the history box */
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;

  .ql-admin-lists > div {
    flex: 1 0 auto; /* Grow and shrink as needed, but don't shrink below auto */
    display: flex;
    flex-direction: column;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: small;
    flex: 1 0 auto; /* Grow and shrink as needed, but don't shrink below auto */

    th,
    td {
      height: 5rem;
      max-width: 15rem;
      padding: 5px;
      border: 1px solid #ccc;
      text-align: left;
    }

    .text-wrap {
      white-space: pre-wrap; /* Allow wrapping */
      word-break: break-all; /* Prevent overflow on narrow screens */
    }

    th {
      background-color: #f5f5f5;
      height: 2rem;
    }
  }

  .pagination {
    align-self: center; /* Push the pagination to the bottom */
    margin-top: auto; /* Add margin to separate the pagination from the table */
  }
}

.user-container {
  width: 70%;
  margin: auto;
}
</style>
