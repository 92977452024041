<template>
    <div class="ctp-protec-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Our Features</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>01</span>
                            </div>
                            <h3>Longevity Specific Report</h3>
                            <p>Longevity.Omics is proud to be the world's first platform dedicated to longevity-specific analysis. Our unique service provides comprehensive reports encompassing a range of critical areas: your longevity genes, healthy predispositions, and traits related to health and fitness. Additionally, we delve into epigenetic age and assess risks for age-related diseases. This targeted approach ensures you receive insights tailored to promoting a long and healthy life.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>02</span>
                            </div>
                            <h3>comprehensive Longevity Database</h3>
                            <p>Our platform leverages an extensive database, enabling a thorough interpretation of genetic and methylation data specifically related to longevity, health, and fitness. Longevity.Omics offers an unrivaled resource, meticulously compiled to support robust and scientifically-backed analysis. This comprehensive foundation allows for a deeper understanding of personal health trajectories and preventive strategies.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>03</span>
                            </div>
                            <h3>Personalized, Individualized Reporting</h3>
                            <p>At Longevity.Omics, we believe in a personalized approach to genetic analysis. Our platform is designed to provide N of 1 reporting, which tracks and reflects changes over time for each individual. This personalized tracking helps in understanding how your genetic profile evolves, influencing various health and fitness traits. Our reports are not just snapshots but ongoing narratives of your genetic health journey.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>04</span>
                            </div>
                            <h3>Expert Genetic Consulting</h3>
                            <p>Our team of experts in genetics and epigenetics is always available to provide detailed consulting services. With profound expertise in the field, we offer interpretations that go beyond the data, giving practical, actionable advice based on your genetic insights. Whether you need help understanding complex genetic information or tailored advice on leveraging your genetic data for health and longevity, Longevity.Omics is here to support every step of the way.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>