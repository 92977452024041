<template>
    <div>
        <NavbarStyleThree />
        <MainBanner />
        <Support />
        <HowItWorks />
        <!-- <Business /> -->
        <Payment />
        <GetStarted />
        <Footer /> 
    </div>
</template>

<script>
import NavbarStyleThree from '../layout/NavbarStyleThree'
import MainBanner from '../epigenomics/MainBanner.vue'
import Support from '../epigenomics/Support'
import Business from '../epigenomics/Business'
import Payment from '../epigenomics/Payment'
import GetStarted from '../epigenomics/GetStarted'
import HowItWorks from '../epigenomics/HowItWorks'
import Footer from '../layout/Footer'

export default {
    name: 'EpigenomicsPage',
    components: {
        NavbarStyleThree,
        MainBanner,
        Support,
        Business,
        Payment,
        GetStarted,
        HowItWorks,
        Footer,
    }
}
</script>