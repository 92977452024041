<template>
  <div>
    <SideBar />
    <div class="currency-transfer-provider-with-background-color">
      <Contact />
    </div>
  </div>
</template>

<script>
import SideBar from "../dashboard/SideBar.vue";

import Contact from "../contact/ContactSide.vue";

export default {
  name: "ContactPage",
  components: {
    Contact,
    SideBar,
  },
};
</script>
