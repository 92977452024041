<template>
    <div>
        <NavbarStyleThree />
        <PageTitle />
        <TermsOfServiceArea />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleThree from '../layout/NavbarStyleThree'
import PageTitle from '../terms-of-service/PageTitle'
import TermsOfServiceArea from '../terms-of-service/TermsOfServiceArea'
import Footer from '../layout/Footer'

export default {
    name: 'TermsOfService',
    components: {
        NavbarStyleThree,
        PageTitle,
        TermsOfServiceArea,
        Footer,
    }
}
</script> 