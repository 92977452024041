<template>
    <div class="support-area ptb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="image">
                        <img src="../../assets/images/dna3.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="support-content">
                        <h2>What is Gen-Decoder?</h2>
                        <p>Gen-Decoder specializes in the analysis and interpretation of genetic data to uncover the secrets of longevity and healthy aging. By focusing on genetic predispositions linked to longevity, our service offers insights into how genes influence lifespan and the aging process. This targeted approach helps in identifying genetic factors that contribute to long-term health and vitality, providing a foundation for personalized anti-aging strategies.</p>
                        <router-link to="/Login" class="btn btn-primary">
                            Find Out More
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Support'
    }
</script>

<style scoped>
.image img { 
    width: 120%;
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Remove any extra space below the image */
    margin: 0 auto; /* Center the image horizontally within its container */
    padding-left: 80px;
}
</style>