<template>
    <div>
        <SideBar />
        <DashboardArea />        
        <DataSubmitAreaWelcome />
    </div>
</template>

<script>
import SideBar from '../dashboard/SideBar.vue';
import DataSubmitAreaWelcome from '../data-submit/DataSubmitAreaWelcome'
import DashboardArea from '../dashboard/DashboardArea';


export default {
    name: 'DataSubmitPage',
    components: {
        SideBar,
        DataSubmitAreaWelcome,
        DashboardArea
    }
}
</script>