<template>
    <div>
        <NavbarStyleThree />
        <PageTitle title="Thank you for your patience" message="Our website is currently under construction. Stay tuned for our exciting new site! Subscribe now to get notified when we launch." />
        <ComingSoonArea />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleThree from '../layout/NavbarStyleThree'
    import PageTitle from '../coming-soon/PageTitle'
    import ComingSoonArea from '../coming-soon/ComingSoonArea'
    import Footer from '../layout/Footer'

    export default {
        name: 'ComingSoon',
        components: {
            NavbarStyleThree,
            PageTitle,
            ComingSoonArea,
            Footer,
        }
    }
</script>