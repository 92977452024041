<template>
    <div class="featured-boxes-area">
        <div class="container">
            <div class="featured-boxes-inner">
                <div class="row m-0">
                    <div class="col-lg-4 col-sm-6 col-md-4 p-0">
                        <div class="single-featured-box">
                            <div class="icon color-fb7756">
                                <img src="@/assets/images/mainpage/genetic.png">
                            </div>
                            <h3>Gen-Decoder</h3>
                            <p>Gen-Decoder provides comprehensive data analysis and reporting services for human whole genome sequencing, tailored for longevity clinicians and medical centres.</p>
                            <router-link to="/Gen-Decoder" class="read-more-btn">Learn More</router-link>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-4 p-0">
                        <div class="single-featured-box">
                            <div class="icon color-facd60">
                                <img src="@/assets/images/mainpage/epigenetic.png">
                            </div>
                            <h3>Epi-Insight</h3>
                            <p>Epi-Insight offers advanced tools for analyzing epigenetic markers related to aging, with a focus on precise epigenetic aging clock, aging acceleration and system age predictions.</p>
                            <router-link to="/Epi-Insight" class="read-more-btn">Learn More</router-link>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-4 p-0">
                        <div class="single-featured-box">
                            <div class="icon color-1ac0c6">
                                <img src="@/assets/images/mainpage/index.png">
                            </div>
                            <h3>Epi-Tracker</h3>
                            <p>Epi-Tracker offers advanced tools for analyzing genetic variations and associated epigenetic changes, supporting personalized health monitoring, disease prevention, and targeted therapies.</p>
                            <router-link to="/Epi-Tracker" class="read-more-btn">Learn More</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Featured'
    }
</script>

<style scoped>
.single-featured-box .icon img {
    width: 50px;  /* Adjust width as needed */
    height: auto;  /* Maintain aspect ratio */
    display: block;  /* Removes bottom space/gap under the image */
    margin-bottom: -10px;  /* Negative bottom margin, pull text closer */
}

.single-featured-box h3 {
    margin-top: -20px;  /* Negative top margin to pull heading closer to the image */
    margin-bottom: 10px;  /* Control space between heading and paragraph */
}

.single-featured-box p {
    margin-top: 0;  /* Optional: adjust if paragraph is too close/far from heading */
}
</style>