<template>
  <div v-if="isAllowed">
    <Preloader v-if="isLoading" />
    <div class="view-wrapper">
      <div class="page-content is-relative">
              <div class="datatable-container">
                <div class="datatable">
                  <div class="notification-box">
                    <span class="notification-message">For Gen-Decoder, each submission with variant data will cost 10 credits, each submission with raw data or mapping data will cost 30 credits</span>
                  </div>
                  <div class="datatable-toolbar">
                    <div class="field">
                      <!-- <div class="control has-icon">
                        <input class="input custom-text-filter" placeholder="Search..." v-model="searchQuery" />
                        <iconify-icon class="iconify form-icon" icon="lucide:search"></iconify-icon>
                      </div> -->
                    </div>
                    <div class="buttons">
                      <button type="button" class="button v-button is-elevated is-primary" @click="goTo('/data-submit/service-selection')">
                        <span class="icon rtl-reflect"><i class="fas fa-plus"></i></span>
                        <span>New Upload</span>
                      </button>
                    </div>
                  </div>

                  <div class="datatable-wrapper">
                    <div class="table-container">
                      <table class="table datatable-table is-fullwidth">
                        <thead>
                          <th>Name</th>
                          <th>Sample ID</th>
                          <th>Service</th>
                          <th>Type</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Reports</th>
                        </thead>
                        <tbody>
                          <tr v-if="paginatedUploads.length === 0">
                            <td colspan="6" class="no-data-notification">No files uploaded</td>
                          </tr>
                          <tr v-else v-for="(upload, index) in paginatedUploads" :key="index">
                            <td>
                              <div class="text-wrap">{{ upload.name }}</div>
                            </td>
                            <td>{{ upload.sampleID }}</td>
                            <td>{{ upload.category }}</td>
                            <td>{{ upload.type }}</td>
                            <td>{{ upload.date }}</td>
                            <td>{{ upload.status }}</td>
                            <td>
                              <a :href="upload.reportLink" v-if="upload.status === 'Ready'">
                                <button class="button v-action">Download</button>
                              </a>
                              <div class="status-text" v-else>In Preparation</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="v-flex flex-pagination pagination is-rounded mt-4" v-if="totalPages > 1">
                    <ul class="pagination-list">
                      <li v-for="page in visiblePages" :key="page">
                        <a
                          :class="{ 'is-current': page === currentPage }"
                          @click="changePage(page)"
                          class="pagination-link"
                          tabindex="0"
                        >
                          {{ page }}
                        </a>
                      </li>
                    </ul>
                    <a
                      :class="{ 'is-disabled': currentPage === 1 }"
                      @click="prevPage"
                      class="pagination-previous has-chevron"
                      tabindex="0"
                    >
                      <iconify-icon class="iconify rtl-hidden" icon="lucide:chevron-left"></iconify-icon>
                      <!-- <iconify-icon class="iconify ltr-hidden" icon="lucide:chevron-right"></iconify-icon> -->
                    </a>
                    <a
                      :class="{ 'is-disabled': currentPage === totalPages }"
                      @click="nextPage"
                      class="pagination-next has-chevron"
                      tabindex="0"
                    >
                      <!-- <iconify-icon class="iconify ltr-hidden" icon="lucide:chevron-left"></iconify-icon> -->
                      <iconify-icon class="iconify rtl-hidden" icon="lucide:chevron-right"></iconify-icon>
                    </a>
                  </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { auth, db } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import Preloader from "../layout/Preloader.vue";
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_CNY_ADMIN,
  USER_STATUS_QL_ADMIN,
} from "../dashboard/dashboardConstants";
import { useRouter } from "vue-router";
import { getUploadHistory } from "./uploadHistory";

export default {
  name: "FileUpload",
  setup() {
    const user = ref(null);
    const userid = ref(null);
    const fileInput = ref(null);
    const files = ref([]);

    const uploadHistory = ref([]);

    const paginatedUploads = ref([]);
    const currentPage = ref(1);
    const totalPages = ref(0);
    const itemsPerPage = ref(5);

    const userCredits = ref(0);

    const isAllowed = ref(false);

    const router = useRouter(); // Get the router instance

    const filesToUpload = ref(null);

    // window.onbeforeunload = function () {
    //   if (isUploading.value) {
    //     return "Are you sure you want to leave?";
    //   }
    // };

    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        user.value = currentUser;
        userid.value = currentUser.uid;

        getUploads();

        getCreditsAndStatus(userid.value);
      } else {
        user.value = null;
        userid.value = null;
        showAlert("Please login to use data submission.");
      }
    });

    const getUploads = async () => {
      uploadHistory.value = await getUploadHistory(
        userid.value
      );
      const start = (currentPage.value - 1) * itemsPerPage.value;

      totalPages.value = Math.ceil(
        uploadHistory.value.length / itemsPerPage.value
      );

      paginatedUploads.value = uploadHistory.value.slice(
        start,
        start + itemsPerPage.value
      );
    };

    const getCreditsAndStatus = async (user_id) => {
      const userDocRef = doc(db, "users", user_id);

      const userSnapshot = await getDoc(userDocRef);

      if (userSnapshot) {
        userCredits.value = userSnapshot.data().credits;

        if (
          userSnapshot.data().status === USER_STATUS_ACTIVE ||
          userSnapshot.data().status === USER_STATUS_QL_ADMIN ||
          userSnapshot.data().status === USER_STATUS_CNY_ADMIN
        ) {
          isAllowed.value = true;
        } else {
          //redirect to dashboard
          router.push("/dashboard"); // Use this to access the Vue instance
        }
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;

        const start = (currentPage.value - 1) * itemsPerPage.value;

        paginatedUploads.value = uploadHistory.value.slice(
          start,
          start + itemsPerPage.value
        );
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;

        const start = (currentPage.value - 1) * itemsPerPage.value;

        paginatedUploads.value = uploadHistory.value.slice(
          start,
          start + itemsPerPage.value
        );
      }
    };

    const goTo = (link) => {
      router.push(link);
    };

    return {
      user,
      userid,
      fileInput,
      files,
      uploadHistory,
      paginatedUploads,
      currentPage,
      totalPages,
      itemsPerPage,
      userCredits,
      isAllowed,
      filesToUpload,
      nextPage,
      prevPage,
      goTo,
    };
  },
};
</script>

<style>

.datatable-container {
  display: flex;
  justify-content: center;
}

.datatable {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 60%;
}

.datatable-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-text-filter {
  width: 200px;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
}

.v-button {
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.v-button:hover {
  background-color: #45a049;
}

.table-container {
  overflow-x: auto;
}

.datatable-table {
  width: 100%;
  border-collapse: collapse;
}

.datatable-table th,
.datatable-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  font-family: 'Roboto', sans-serif;
}

.datatable-table th:first-child,
.datatable-table td:first-child {
  color: #000000;
  font-weight: bold;
}


.datatable-table td:not(:first-child) {
  color: #333333;
}

.status-text{
  text-align: left;
  font-family: 'Roboto', sans-serif;
}

.flex-media {
  display: flex;
  align-items: center;
}


.v-action {
  background-color: transparent;
  color: black;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;
}

.v-action:hover {
  border-color: #42b983;
  color: #42b983;
}

.flex-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagination-link {
  padding: 6px 12px;
  margin: 0 4px;
  border-radius: 4px;
  background-color: #e0e0e0;
  color: #333333;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-link.is-current {
  background-color: #2196f3;
  color: #ffffff;
}

.pagination-previous,
.pagination-next {
  padding: 6px 12px;
  margin: 0 4px;
  border-radius: 4px;
  background-color: #e0e0e0;
  color: #333333;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-previous.is-disabled,
.pagination-next.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.notification-box {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.notification-message {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

.notification-box {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.notification-message {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.no-data-notification {
  text-align: center;
  padding: 20px;
  color: #999;
  font-style: italic;
}
</style>
