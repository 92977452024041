<template>
    <div>
        <SideBar />
        <DataSubmitAreaNav />
        <form class="feedback-wrapper" novalidate>
            <div class="inner-wrapper">
                <div class="feedback-area">
                <!-- <div class="container"> -->
                    <div class="feedback-title">
                        <h2>Select Your Data Type for {{ selectedService }}</h2>
                    </div> 
                    <div class="types" v-if="selectedService==='Gen-Decoder'">
                        <div class="columns">
                            <div
                                v-for="(type, index) in wgsTypes"
                                :key="index"
                                class="column is-4"
                            >
                                <div class="feedback-card">
                                    <img :src="type.image" alt="image">
                                    <h3>{{ type.title }}</h3>
                                    <p>{{ type.description }}</p>
                                    <div class="button-wrapper">
                                        <button 
                                            class="button is-primary v-button is-rounded is-bold is-elevated" 
                                            @click.prevent="goToLink(type.nextStep, type.key)">
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>     
                    <div class="types" v-else>
                        <ComingSoonArea />
                    </div>
                    <div class="feedback-buttons">
                        <div class="feedback-buttons-inner">
                            <router-link class="button v-button is-light" to="/data-submit/service-selection">Previous</router-link>
                            <router-link class="button v-button is-primary" to="/data-submit">Cancel</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import DataSubmitAreaNav from './DataSubmitAreaNav.vue';
import SideBar from '../dashboard/SideBar.vue';
import ComingSoonArea from './ComingSoonArea.vue';

export default {
    name: 'DataSelection',
    data() {
        return {
            wgsTypes: [
                {
                    title: 'Raw Data',
                    description: 'Raw data\'s file format is .fastq.gz, with approximately 30GB in size. You can upload directly the raw data or put the raw data in a google drive and share the link with us.',
                    image: require('@/assets/images/single_fastq.png'),
                    key: 'raw',
                    nextStep: 'DataUpload',
                },
                {
                    title: 'Mapping Data',
                    description: 'Mapping data\'s file format is .bam, with approximately 30GB in size. You can upload directly the mapping data or put the mapping data in a google drive and share the link with us.',
                    image: require('@/assets/images/single_bam.png'),
                    key: 'mapping',
                    nextStep: 'DataUpload',
                },
                {
                    title: 'Variant Data',
                    description: 'Variant data\'s file format is .vcf.gz, with approximately 1GB in size.',
                    image: require('@/assets/images/single_vcf.png'),
                    key: 'variant',
                    nextStep: 'DataUpload',
                },
            ],
        };
    },
    components: {
        DataSubmitAreaNav,
        SideBar,
        ComingSoonArea,
    },
    methods: {
        goToLink(nextStep, key) {
            this.$router.push({
                name: nextStep,
                query: {
                    selectedService: this.selectedService,
                    selectedType: key,
                }
            });
        },
    },
    computed: {
        selectedService() {
            return this.$route.query.selectedService;
        },
    },
};
</script>