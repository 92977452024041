<template>
    <div>
        <nav 
            class="feedback-nav" 
            :step="step"
            :title="title"
        >
            <div class="feedback-title">
                <span class="title-wrap">
                    Step {{ step }}: 
                    <span>{{ title }}</span>
                </span>
            </div>
            <div class="dropdown">
                <div class="is-trigger" role="button" tabindex="0">
                    <iconify-icon
                        class="iconify"
                        icon="lucide:chevron-down"
                        @click="handleClick()"
                    ></iconify-icon>
                </div>
                <div class="dropdown-menu" role="menu" :class="{ 'is-active': isActive }">
                    <div class="dropdown-content">
                        <a 
                            v-for="link in links"
                            :key="link.id"
                            :href="link.url"
                            :ref="'link-' + link.id"
                            class="is-disable dropdown-item"
                            tabindex="0">
                            {{ link.text }}
                        </a>
                    </div>
                </div>
            </div>
        </nav>
        <progress 
            class="feedback-progress" 
            :value="progress"
            max="100">
            {{ progress }}%
        </progress>
    </div>
    

</template>

<script>
export default {
    name: 'FeedbackNav',
    data() {
        return {
            step: 1,
            title: 'Feedback Form',
            isActive: false,
            progress: 33,
            links: [
                { id: 1, text: 'Step 1: Service Selection', url: '/data-submit/service-selection', status: false },
                { id: 2, text: 'Step 2: Data Type Selection', url: '/feedback/data-selection', status: false },
                { id: 3, text: 'Step 3: Sample Data Submission', url: '/data-submit/data-upload', status: false },
            ],
        };
    },
    methods: {
        handleClick() {
            this.isActive = !this.isActive;
        },

        handleClickOutside(event) {
            if (this.isActive && !this.$el.contains(event.target)) {
                this.isActive = false;
            }
        },


    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        // check current link
        if (window.location.pathname === '/data-submit/service-selection') {
            this.step = 1;
            this.title = 'Service Selection';
            this.links[0].status = true;
            this.progress = 33;
        } else if (window.location.pathname.includes('/data-submit/data-selection')) {
            this.step = 2;
            this.title = 'Data Type Selection';
            this.links[0].status = true;
            this.links[1].status = true;
            this.progress = 66;
        } else if (window.location.pathname.includes('/data-submit/data-upload')) {
            this.step = 3;
            this.title = 'Sample Data Submission';
            this.links[0].status = true;
            this.links[1].status = true;
            this.links[2].status = true;
            this.progress = 100;
        }

        this.selectedService = this.$route.query.selectedService;
        this.selectedType = this.$route.query.selectedType;

        if (this.selectedService) {
            this.links[1].url = `/data-submit/data-selection?selectedService=${this.selectedService}`;
        }
        if (this.selectedType) {
            this.links[2].url = `/data-submit/data-upload?selectedService=${this.selectedService}&selectedType=${this.selectedType}`;
        }

        for (let i = 0; i < this.links.length; i++) {
            if (this.links[i].status) {
                // find that element using id and remove class: is-disable
                const element = this.$refs[`link-${this.links[i].id}`][0];
                element.classList.remove('is-disable');
            }
        }
    },
    // computed: {
    //     selectedService() {
    //         return this.$route.query.selectedService;
    //     },
    //     selectedType() {
    //         return this.$route.query.selectedType;
    //     },
    // },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
};

</script>