<template>
    <div>
        <NavbarStyleThree />
        <PageTitle />
        <div style="margin-top: 100px;">
            <Publications />
        </div>
        <!-- <Technology />
        <Longevity /> -->
        <Footer />
    </div>
</template>

<script>
import NavbarStyleThree from '../layout/NavbarStyleThree'
import Footer from '../layout/Footer'
import Publications from '../science/Publications'
import PageTitle from '../science/PageTitle'
// import Technology from '../science/Technology'
// import Longevity from '../science/Longevity'

export default {
    name: 'SciencePage',
    components: {
        NavbarStyleThree,
        Footer,
        Publications,
        PageTitle,
        // Technology,
        // Longevity,
    }
}
</script>