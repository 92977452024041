<template>
    <div class="invoicing-area ptb-70">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="invoicing-content">
                        <h2>Easy Payment to borrow free get a better filling at home</h2>
                        <div class="bar"></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                        <router-link to="/contact" class="btn btn-primary">
                            Get Started
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="invoicing-image">
                        <div class="main-image">
                            <img src="../../assets/images/invoicing/invoicing-1.png" alt="image">
                            <img src="../../assets/images/invoicing/invoicing-2.png" alt="image">
                            <img src="../../assets/images/invoicing/invoicing-3.png" alt="image">
                            <img src="../../assets/images/invoicing/invoicing-4.png" alt="image">
                        </div>

                        <div class="main-mobile-image">
                            <img src="../../assets/images/invoicing/invoicing-main-pic.png" alt="image">
                        </div>

                        <div class="circle-image">
                            <img src="../../assets/images/invoicing/invoicing-circle-1.png" alt="image">
                            <img src="../../assets/images/invoicing/invoicing-circle-2.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Invoicing'
    }
</script>