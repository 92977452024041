<template>
    <div>
        <NavbarStyleThree />
        <MainBanner />
        <Support />
        <Currency />
        <!-- <HowItWorks /> -->
        <!-- <Business /> -->
        <Payment />
        <GetStarted />
        <Footer /> 
    </div>
</template>

<script>
import NavbarStyleThree from '../layout/NavbarStyleThree'
import MainBanner from '../index/MainBanner.vue'
import Support from '../index/Support'
import Business from '../index/Business'
import Payment from '../index/Payment'
import GetStarted from '../index/GetStarted'
import HowItWorks from '../index/HowItWorks'
import Currency from '../index/Currency'
import Footer from '../layout/Footer'

export default {
    name: 'EpigenomicsPage',
    components: {
        NavbarStyleThree,
        MainBanner,
        Support,
        Currency,
        Business,
        Payment,
        GetStarted,
        HowItWorks,
        Footer,
        
    }
}
</script>