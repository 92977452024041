<template>
    <div class="login-area">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-image">
                    <img src="../../assets/images/login-bg.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="login-form">
                                <div class="logo">
                                    <router-link to="/">
                                        <img src="../../assets/images/Longevity.omics-logo.png" alt="image">
                                    </router-link>
                                </div>

                                <h3>Reset Your Password</h3>
                                <!-- <p>New to Quantum Life? <router-link to="/register">Register</router-link></p> -->

                                <!-- Success and Error Messages -->
                                <div v-if="successMessage" class="alert-success">{{ successMessage }}</div>
                                <div v-if="errorMessage" class="alert-danger">{{ errorMessage }}</div>

                                <form @submit.prevent="sendResetEmail">
                                    <h4 class="reset-info">Enter your account's verified email address and we will send you a password reset link.</h4>

                                    <div class="form-group">
                                        <input type="email" name="email" id="email" placeholder="Your email address" class="form-control" v-model="email">
                                        <span v-if="emailError" class="error">{{ emailError }}</span>
                                    </div>

                                    <button type="submit" class="btn btn-primary">Send password reset email</button>
                                </form>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { auth } from '@/firebase';
    import { sendPasswordResetEmail } from 'firebase/auth';


    export default {
        name: 'LoginArea',
        data() {
            return {
                email: '',
                emailError: '',
                successMessage: '',
                errorMessage: '',
                router_link: '/login'
            }
        },
        watch: {
            email(value) {
                if (!value || value.length === 0) {
                    this.emailError = 'Email is required';
                } 
                else if (!/\S+@\S+\.\S+/.test(value)) {
                    this.emailError = 'Please enter a valid email';
                }
                else {
                    this.emailError = '';
                }
            },
        },
        methods: {
            formValidate() {
                if (!this.email) {
                    this.emailError = !this.email ? 'Email is required' : '';
                    return false;
                }

                if (!/\S+@\S+\.\S+/.test(this.email)) {
                    this.emailError = 'Please enter a valid email';
                    return false;
                }

                return true;
            },

            sendResetEmail() {
                
                if (!this.formValidate()) {
                    return;
                }
                sendPasswordResetEmail(auth, this.email)
                    .then(() => {
                        // Send reset password email
                        this.successMessage = 'Password reset email sent. Please check your email. Redirecting to login page...';
                        this.errorMessage = '';
                        setTimeout(() => {
                            this.$router.push(this.router_link);
                        }, 3000);
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.error(errorCode, errorMessage);
                        this.errorMessage = errorMessage;
                        this.successMessage = '';
                    });
            }
        }
    }
</script>

<style scoped lang="scss">
.reset-info {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
    text-align: left;
}


</style>