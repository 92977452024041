<template>
    <div class="ctp-contact-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-12">
                    <div class="ctp-contact-form">
                        <h3>Any Enquiries or Comments?</h3>

                        <form>
                            <div class="form-group">
                                <label>First name</label>
                                <input type="text" name="name" id="name" class="form-control" required v-model="firstname">
                                <span v-if="firstnameError" class="error">{{ firstnameError }}</span>
                            </div>

                            <div class="form-group">
                                <label>Surname</label>
                                <input type="text" name="name" id="name" class="form-control" required v-model="surname">
                                <span v-if="surnameError" class="error">{{ surnameError }}</span>
                            </div>

                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" name="email" id="email" class="form-control" required v-model="email">
                                <span v-if="emailError" class="error">{{ emailError }}</span>
                            </div>

                            <div class="form-group">
                                <label>Telephone</label>
                                <input type="text" name="phone_number" id="phone_number" class="form-control" required v-model="phone_number">
                                <span v-if="phone_numberError" class="error">{{ phone_numberError }}</span>
                            </div>

                            <div class="form-group">
                                <label>Subject</label>
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required v-model="msg_subject">
                                <span v-if="msg_subjectError" class="error">{{ msg_subjectError }}</span>
                            </div>

                            <div class="form-group">
                                <label>Message</label>
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6" required v-model="message"></textarea>
                                <span v-if="messageError" class="error">{{ messageError }}</span>
                            </div>

                            <button type="submit" class="btn btn-primary" @click.prevent="submitForm">Send Us Your Enquiry</button>
                        </form>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="ctp-contact-information">
                        <div class="information-box">
                            <h3>Our Contact Details</h3>

                            <ul class="contact-info">
                                <li class="address">
                                    <span class="sub">Address:</span>
                                    17W Building of HKSTP, NT, Hong Kong
                                </li>
                                <li class="email">
                                    <span class="sub">Email:</span>
                                    <!-- <div class="info">
                                        <span>Personal</span>
                                        <a href="mailto:barry@quantumlife.tech">barry@quantumlife.tech</a>
                                    </div> -->
                                    <div class="info">
                                        <!-- <span>Business</span> -->
                                        <a href="mailto:info@quantumlife.tech">info@quantumlife.tech</a>
                                    </div>
                                </li>
                                <li class="phone">
                                    <span class="sub">Phone:</span>
                                    <!-- <div class="info">
                                        <span>Personal</span>
                                        <a href="tel:+852 60967907">+852 60967907</a>
                                    </div> -->
                                    <div class="info">
                                        <!-- <span>Business</span> -->
                                        <a href="tel:+852 69922647">+852 69922647</a>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="information-box">
                            <h3>Office Opening Hours</h3>

                            <ul class="time-info">
                                <li class="d-flex align-items-center justify-content-between">
                                    <span class="color">Monday - Friday:</span>
                                    <span>9:00am - 6:00pm</span>
                                </li>
                                <!-- <li class="d-flex align-items-center justify-content-between">
                                    <span class="color">Friday:</span>
                                    <span>10:00am - 6:00pm</span>
                                </li>
                                <li class="d-flex align-items-center justify-content-between">
                                    <span class="color">Saturday:</span>
                                    <span>10:00am - 2:00pm</span>
                                </li> -->
                            </ul>
                        </div>

                        <!-- <div class="information-map">
                            <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.183428676291!2d-73.9983046843594!3d40.71397834543098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a263f3910db%3A0xd6e6cdf32a6b11b1!2s27%20Division%20St%2C%20New%20York%2C%20NY%2010002%2C%20USA!5e0!3m2!1sen!2sbd!4v1659370483233!5m2!1sen!2sbd"></iframe>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { doc, setDoc } from 'firebase/firestore';

export default {
    name: 'Contact',
    data() {
        return {
            userID: null,
            firstname: '',
            surname: '',
            email: '',
            phone_number: '',
            msg_subject: '',
            message: '',
            firstnameError: '',
            surnameError: '',
            emailError: '',
            phone_numberError: '',
            msg_subjectError: '',
            messageError: ''
        }
    },
    watch: {
        firstname(value) {
            if (!value || value.length === 0) {
                this.firstnameError = 'First name is required';
            } else {
                this.firstnameError = '';
            }
        },
        surname(value) {
            if (!value || value.length === 0) {
                this.surnameError = 'Surname is required';
            } else {
                this.surnameError = '';
            }
        },
        email(value) {
            if (!value || value.length === 0) {
                this.emailError = 'Email is required';
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                this.emailError = 'Please enter a valid email';
            } else {
                this.emailError = '';
            }
        },
        phone_number(value) {
            if (!value || value.length === 0) {
                this.phone_numberError = 'Phone number is required';
            } else {
                this.phone_numberError = '';
            }
        },
        msg_subject(value) {
            if (!value || value.length === 0) {
                this.msg_subjectError = 'Subject is required';
            } else {
                this.msg_subjectError = '';
            }
        },
        message(value) {
            if (!value || value.length === 0) {
                this.messageError = 'Message is required';
            } else {
                this.messageError = '';
            }
        }
    },
    methods: {
        formValidate(){
            if (!this.firstname || !this.surname || !this.email || !this.phone_number || !this.msg_subject || !this.message) {
                this.firstnameError = !this.firstname ? 'First name is required' : '';
                this.surnameError = !this.surname ? 'Surname is required' : '';
                this.emailError = !this.email ? 'Email is required' : '';
                this.phone_numberError = !this.phone_number ? 'Phone number is required' : '';
                this.msg_subjectError = !this.msg_subject ? 'Subject is required' : '';
                this.messageError = !this.message ? 'Message is required' : '';
                return false;
            }

            if (!/\S+@\S+\.\S+/.test(this.email)) {
                this.emailError = 'Please enter a valid email';
                return false;
            }

            return true;
        },

        submitForm() {
            if (!this.formValidate()) {
                return;
            }
            const feedbackRef = doc(db, 'feedbacks', this.userID, 'feedbacks', new Date().toISOString());
            const feedbackData = {
                firstname: this.firstname,
                surname: this.surname,
                email: this.email,
                phone_number: this.phone_number,
                msg_subject: this.msg_subject,
                message: this.message,
                created_at: new Date()
            };

            setDoc(feedbackRef, feedbackData)
                .then(() => {
                    // this.firstname = '';
                    // this.surname = '';
                    // this.email = '';
                    // this.phone_number = '';
                    // this.msg_subject = '';
                    // this.message = '';
                    // this.firstnameError = '';
                    // this.surnameError = '';
                    // this.emailError = '';
                    // this.phone_numberError = '';
                    // this.msg_subjectError = '';
                    // this.messageError = '';
                    alert('Feedback submitted successfully');

                    window.location.reload();
                })
                .catch((error) => {
                    console.error('Error submitting feedback: ', error);
                    alert('Error submitting feedback');
                });
        }
    },
    created() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.userID = user.uid;
            }
        });
    }
}
</script>

<style scoped lang="scss">
.error {
    color: red;
    font-weight: 400;
    margin-bottom: 15px;

}
</style>