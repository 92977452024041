<template>
    <div class="payment-features-area ptb-70">
        <div class="container">
            <div class="payment-features-overview">
                <div class="payment-features-content">
                    <div class="content">
                        <h2>Understanding Epigenetic Age</h2>
                        <p><b>What is Epigenetic Age?</b></p>
                        <p>Epigenetic age, often referred to as biological age, is a measure that reflects an individual's aging process more accurately than their chronological age. At the heart of this concept is the understanding that while all individuals age, the rate and manner in which they age can vary significantly due to a variety of factors including genetics, lifestyle, and environmental influences.</p>
                        <p><b>What Determines Epigenetic Age?</b></p>
                        <p>Epigenetic age is primarily determined by the patterns of DNA methylation, a biochemical process that modifies the DNA in our cells. DNA methylation patterns change as we age, and these changes can be tracked to infer a person's biological age. Researchers have developed several "epigenetic clocks" based on specific sets of DNA methylation sites that correlate strongly with age-related changes. These clocks can predict an individual’s lifespan, healthspan, and susceptibility to specific age-related diseases.</p>
                        <p><b>How is Epigenetic Age Calculated?</b></p>
                        <p>Epigenetic age is determined by analyzing DNA methylation patterns from a biological sample like blood or saliva. The process involves extracting DNA, treating it to reveal methylation levels, and then comparing these levels to established models known as "epigenetic clocks." These clocks correlate specific DNA modifications with chronological age, allowing a computation of biological age, which offers insights into an individual's aging process beyond their actual years.</p>
                        <!-- <router-link to="/login" class="link-btn">
                            Get started now
                        </router-link> -->
                    </div>
                </div>
                <div class="payment-features-content">
                    <div class="content">
                        <h2>The Science Behind Gen-Decoder</h2>
                        <p>Epi-Insight harnesses the power of epigenetics, specifically focusing on DNA methylation, to provide deeper insights into the biological aging process. At the core of our platform is the integration of advanced genomic technologies and bioinformatics, which allows us to analyze and interpret complex epigenetic data with high precision.</p>
                        <p><b>Advanced Genomic Technologies: </b>We utilize state-of-the-art sequencing and array-based methods to accurately measure DNA methylation across thousands of specific sites in the genome. These sites have been carefully selected based on extensive research that identifies them as key indicators of biological age.</p>
                        <p><b>Bioinformatics and Machine Learning: </b>Once methylation data is collected, our sophisticated algorithms come into play. We employ machine learning techniques to analyze patterns in the data, comparing them against established epigenetic clocks. These clocks are essentially predictive models that have been trained on large datasets to correlate specific methylation patterns with chronological and biological age.</p>
                        <p><b>User-Focused Reports: </b>The culmination of our process is the generation of detailed, user-friendly reports that provide actionable insights into an individual's aging process. These reports not only highlight the biological age but also provide a detailed analysis of aging pace and potential organ-specific aging, enabling users to make informed decisions about their health and lifestyle.</p>
                        <p>Through the science that drives Epi-Insight, we strive to empower clinicians and researchers with the most accurate and actionable epigenetic information, making a profound impact on the understanding and management of the human aging process.</p>
                        <!-- <router-link to="/login" class="link-btn">
                            Get started now
                        </router-link> -->
                    </div>
                </div>
                <div class="payment-features-image">
                    <!-- <div class="image">
                        <img src="../../assets/images/dna4.png" alt="image">
                    </div> -->
                </div>
            </div>

            <div class="payment-features-overview">
                <!-- <div class="payment-features-content">
                    <div class="content">
                        <h2>The Science Behind Quantum Genomics</h2>
                        <p>Quantum Genomics builds on cutting-edge genetic research and technology to analyze DNA with a focus on longevity. We use whole genome sequencing (WGS) to decode DNA efficiently, combined with a powerful bioinformatics platform that integrates genetic data with clinical insights. This comprehensive approach highlights genetic markers linked to longevity, providing a deeper understanding of how these factors contribute to aging and health.</p>
                        <router-link to="/contact" class="link-btn">
                            Get started now
                        </router-link>
                    </div>
                </div> -->

                <div class="payment-features-image">
                    <!-- <div class="image">
                        <img src="../../assets/images/dna1.jpg" alt="image">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Payment'
    }
</script>
