import { createWebHistory, createRouter } from "vue-router";

// All Pages
import MainPage from "../components/pages/MainPage";
// import LoanProvidersPage from "../components/pages/LoanProvidersPage";
// import DigitalBankingPage from "../components/pages/DigitalBankingPage";
// import FinancialServicesPage from "../components/pages/FinancialServicesPage";
// import BankersEntrepreneursPage from "../components/pages/BankersEntrepreneursPage";
// import SocialFinancePage from "../components/pages/SocialFinancePage";
// import CurrencyTransferPage from "../components/pages/CurrencyTransferPage";
import AboutUsPage from "../components/pages/AboutUsPage";
import GenomicsPage from "../components/pages/GenomicsPage.vue";
import EpigenomicsPage from "../components/pages/EpigenomicsPage.vue";
import IndexPage from "../components/pages/IndexPage.vue";
// import AboutTwoPage from "../components/pages/AboutTwoPage";
// import HowItWorksPage from "../components/pages/HowItWorksPage";
// import FeaturesOne from "../components/pages/FeaturesOne";
// import FeaturesTwo from "../components/pages/FeaturesTwo";
// import Team from "../components/pages/Team";
// import Pricing from "../components/pages/Pricing";
// import PersonalPage from "../components/pages/PersonalPage";
// import BusinessPage from "../components/pages/BusinessPage";
import Login from "../components/pages/Login";
import Register from "../components/pages/Register";
// import ProfilePage from "../components/pages/ProfilePage.vue";
// import Error from "../components/pages/Error";
// import Faq from "../components/pages/Faq";
// import BlogOne from "../components/pages/BlogOne";
// import BlogTwo from "../components/pages/BlogTwo";
// import BlogThree from "../components/pages/BlogThree";
import ContactPage from "../components/pages/ContactPage";
// import ContacTwoPage from "../components/pages/ContacTwoPage";
// import PrivacyPolicy from "../components/pages/PrivacyPolicy";
import TermsOfService from "../components/pages/TermsOfService.vue";
import PrivacyPolicy from "../components/pages/PrivacyPolicy.vue";
import ComingSoon from "../components/pages/ComingSoon.vue";

import DataSubmitPageWelcome from "../components/pages/DataSubmitPageWelcome.vue";
import DataSubmitAreaStep1 from "../components/data-submit/DataSubmitAreaStep1.vue";
import DataSubmitAreaStep2 from "../components/data-submit/DataSubmitAreaStep2.vue";
import DataSubmitAreaStep3 from "../components/data-submit/DataSubmitAreaStep3.vue";

import ResetPassword from "../components/log-in/ResetPassword.vue";
import ResetPasswordUpdate from "../components/log-in/ResetPasswordUpdate.vue";
import Redirect from "../components/redirect/Redirect.vue";
import Dashboard from "../components/pages/Dashboard.vue";
import SampleReports from "../components/pages/SampleReports.vue";
import SciencePage from "../components/pages/SciencePage.vue";
import JoinWaitlist from "../components/pages/JoinWaitlist.vue";

import QLAdminPage from "../components/pages/QLAdminPage.vue";

import CnyAdminPage from "../components/pages/CnyAdminPage.vue";

import Feedback from "../components/feedback/Feedback.vue";
import ContactSidePage from "../components/pages/ContactSidePage.vue";

const routes = [
  { path: "/", component: MainPage },
  // { path: "/loan-providers", component: LoanProvidersPage },
  // { path: "/digital-banking", component: DigitalBankingPage },
  // { path: "/financial-services", component: FinancialServicesPage },
  // { path: "/bankers-entrepreneurs", component: BankersEntrepreneursPage },
  // { path: "/social-finance", component: SocialFinancePage },
  // { path: "/currency-transfer", component: CurrencyTransferPage },
  { path: "/about-us", component: AboutUsPage },
  { path: "/Gen-Decoder", component: GenomicsPage },
  { path: "/Epi-Insight", component: EpigenomicsPage },
  { path: "/Epi-Tracker", component: IndexPage },
  // { path: "/profile", component: ProfilePage },
  // { path: "/about-two", component: AboutTwoPage },
  // { path: "/how-it-works", component: HowItWorksPage },
  // { path: "/features-1", component: FeaturesOne },
  // { path: "/features-2", component: FeaturesTwo },
  // { path: "/team", component: Team },
  // { path: "/pricing", component: Pricing },
  // { path: "/personal", component: PersonalPage },
  // { path: "/business", component: BusinessPage },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  // { path: "/error", component: Error },
  // { path: "/faq", component: Faq },
  // { path: "/blog-1", component: BlogOne },
  // { path: "/blog-2", component: BlogTwo },
  // { path: "/blog-details", component: BlogThree },
  { path: "/contact", component: ContactPage },

  { path: "/contact-side", component: ContactSidePage },
  // { path: "/contact-two", component: ContacTwoPage },
  // { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-of-service", component: TermsOfService },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/coming-soon", component: ComingSoon },

  { path: "/data-submit", component: DataSubmitPageWelcome },
  { path: "/data-submit/service-selection", component: DataSubmitAreaStep1 },
  { 
    path: "/data-submit/data-selection", 
    name: "DataSelection",
    component: DataSubmitAreaStep2,
  },
  { 
    path: "/data-submit/data-upload", 
    name: "DataUpload",
    component: DataSubmitAreaStep3,
  },

  { path: "/password-reset", component: ResetPassword },
  { path: "/password-reset-update", component: ResetPasswordUpdate },
  { path: "/auth-action", component: Redirect },
  { path: "/dashboard", component: Dashboard },
  { path: "/sample-reports", component: SampleReports },
  { path: "/science", component: SciencePage },
  { path: "/join-waitlist", component: JoinWaitlist },

  { path: "/qladmin", component: QLAdminPage },
  { path: "/cnyadmin", component: CnyAdminPage },
  { path: "/feedback", component: Feedback },

];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
