<template>
    <div class="features-area ptb-70 bg-f6f4f8">
        <div class="container">
            <div class="section-title">
                <h2>Our Features</h2>
                <div class="bar"></div>
                <p>Explore the cutting-edge features of Quantum Omics, tailored to streamline complex genetic and epigenetic analyses. Our platform equips you with advanced tools for seamless data integration and personalized health insights, enhancing your approach to longevity and healthy aging.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-settings"></i>
                        </div>

                        <h3>Streamlined Data Analysis</h3>
                        <p>Simplify complex omics data analysis with our intuitive platform, designed for ease of use without requiring coding expertise.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-f78acb">
                            <i class="flaticon-envelope-of-white-paper"></i>
                        </div>

                        <h3>Integrated Multi-Omics Approach</h3>
                        <p>Combine genetic, epigenetic, and risk assessment data using Quantum Genomics, Quantum Epigenomics, and Quantum Index for a holistic view of health and aging.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-cdf1d8">
                            <i class="flaticon-menu"></i>
                        </div>

                        <h3>Advanced Reporting Tools</h3>
                        <p>Generate comprehensive, easy-to-understand reports that translate detailed omics data into actionable insights for personalized health strategies.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-c679e3">
                            <i class="flaticon-info"></i>
                        </div>

                        <h3>High Security Standards</h3>
                        <p>Ensure the confidentiality and integrity of sensitive data with our platform, built to comply with leading healthcare data protection regulations.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-eb6b3d">
                            <i class="flaticon-cursor"></i>
                        </div>

                        <h3>Customizable Analysis Options</h3>
                        <p>Tailor your data analysis based on specific clinical needs and research goals, enabling flexible and targeted investigations.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-alarm"></i>
                        </div>

                        <h3>Real-Time Updates and Support</h3>
                        <p>Benefit from continuous platform updates and dedicated support to keep your practice at the forefront of genomic and epigenomic research.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FeaturesTwo'
    }
</script>