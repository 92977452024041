<template>
    <div class="verification-window">
        <div class="box">
            <div class="box-content">
                <img src="@/assets/images/Longevity.omics-logo.png" alt="email" class="box-image">
                <h3>{{ title }}</h3>
                <p>{{ content }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { auth } from '@/firebase';
import { applyActionCode } from 'firebase/auth';

export default {
    name: 'Redirect',
    data() {
        return {
            mode: '',
            oobCode: '',
            title: 'Please wait...',
            content: 'Redirecting...',
            router_link: ''
        }
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        this.mode = urlParams.get('mode');
        this.oobCode = urlParams.get('oobCode');
        if (this.mode === 'resetPassword') {
            this.router_link = `/password-reset-update?mode=${this.mode}&oobCode=${this.oobCode}`;
            this.$router.push(this.router_link);
        } else if (this.mode === 'verifyEmail') {
            applyActionCode(auth, this.oobCode).then(() => {
                this.title = 'Email Verified';
                this.content = 'Your email has been verified. You can now log in.';
            }).catch((error) => {
                console.log(error);
                if (error.code === 'auth/expired-action-code') {
                    this.title = 'Link Expired';
                    this.content = 'The link has expired. Please try again.';
                } 
                else if (error.code === 'auth/invalid-action-code') {
                    this.title = 'Invalid Link';
                    this.content = 'You can only use the link once. If you have already verified your email, please log in.';
                }
                else {
                    this.title = error.code;
                    this.content = error.message;
                }
            });
        }
        else {
            console.error('Invalid mode: ', this.mode);
            this.title = 'Invalid Request';
            this.content = 'You are seeing this page because you clicked on an invalid link. Please try again.';
        }
    }
}

</script>