<template>
    <div class="payment-features-area ptb-70">
        <div class="container">
            <div class="payment-features-overview">
                <div class="payment-features-content">
                    <div class="content">
                        <h2>Genetic Variants and Epigenetic Influence on Disease Risk</h2>
                        <p>Genetic variants, including single nucleotide polymorphisms (SNPs), insertions, and deletions, are embedded in an individual's DNA and set a baseline for disease susceptibility. These static genetic factors are crucial for understanding an individual's inherent risk for various diseases.</p>
                        <p>Epigenetics, particularly DNA methylation, introduces a dynamic layer to disease risk assessment. Methylation, the addition of a methyl group to DNA at specific sites, influences gene expression without changing the DNA sequence itself. This process is responsive to environmental and lifestyle factors like diet and stress, allowing gene expression to be modulated according to external conditions.</p>
                        <p>The interaction between genetic variants and epigenetic changes can significantly alter gene activity, potentially activating or silencing genes involved in disease. For example, a genetic predisposition to a disease could be mitigated by epigenetic modifications that reduce gene expression, or vice versa.</p>
                        <p>By analyzing both genetic and epigenetic factors, we gain a deeper insight into disease mechanisms and potential interventions. This comprehensive approach to health allows for personalized strategies in disease prevention, monitoring, and treatment, considering each individual's unique genetic and epigenetic profile.</p>
                    </div>
                </div>
                <div class="payment-features-content">
                    <div class="content">
                        <h2>The Science Behind Epi-Tracker</h2>
                        <p>Epi-Tracker integrates cutting-edge genetic and epigenetic technologies to deliver comprehensive insights into disease risk. This process begins with whole genome sequencing (WGS) to identify genetic variants linked to diseases, followed by detailed DNA methylation profiling to assess epigenetic markers at specific CpG sites, crucial for gene regulation.</p>
                        <p><b>Integration via meQTL Mapping: </b>The core of Epi-Tracker's approach involves methylation quantitative trait loci (meQTL) mapping, which identifies how genetic variants influence methylation patterns and, consequently, gene expression. This method pinpoints the interaction between static genetic factors and dynamic epigenetic modifications, allowing for a detailed understanding of how external factors like lifestyle can impact disease risk over time.</p>
                        <p>Through advanced bioinformatics, Epi-Tracker provides a dynamic, real-time view of health risks, offering actionable insights tailored to individual genetic and epigenetic profiles. This integrated approach empowers personalized health assessments and proactive disease management.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Payment'
    }
</script>
