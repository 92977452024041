<template>
  <div v-if="isAdmin">
    <div class="title-wrapper">
      <div class="page-title-content-submit">
        <h2 class="title is-bold">Company Administration Page</h2>
        <p class="light-text">Manage the user accounts in your company here.</p>
      </div>
    </div>
    <!-- Popup for Alerts and Completion Messages -->
    <div v-if="showPopup" class="popup-overlay" @click.self="closePopup">
      <div :class="['popup', popupType]">
        <button class="close" @click="closePopup">&times;</button>
        <p>{{ popupMessage }}</p>
      </div>
    </div>

    <div class="form-group cny">
      <!-- Add employee  -->
      <div class="history-box sub">
        Invite an employee to the company
        <input type="text" id="email" placeholder="Add email address..." />
        <button class="button v-button is-elevated is-rounded is-primary" @click="addEmployee()">Invite</button>
      </div>
      <div>
        <!--List of subordinates-->
        <div class="history-box sub">
          <h3 class="title is-bold">List of subordinates</h3>

          <div>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Credits</th>
                  <th>Add Credits</th>
                  <th>Remove Credits</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sub, index) in subs" :key="index">
                  <td>{{ sub.name }}</td>
                  <td>{{ sub.email }}</td>

                  <td>{{ sub.jobFunction }}</td>
                  <td>
                    {{ sub.credits }}
                  </td>
                  <td>
                    <input
                      id="creditsAdd"
                      class="input-credits"
                      placeholder="Enter credits"
                      v-model="sub.creditsToAdd"
                    />
                  </td>
                  <td>
                    <input
                      id="creditsRemove"
                      class="input-credits"
                      placeholder="Enter credits"
                      v-model="sub.creditsToRemove"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <button class="button v-button is-elevated is-rounded is-primary" @click="dispatchCredits()">Dispatch credits</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import emailjs from "@emailjs/browser";

import { ref } from "vue";
import { auth, db } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useRouter } from "vue-router";
import {
  STATUS_LIST,
  USER_STATUS_ACTIVE,
  USER_STATUS_CNY_ADMIN,
  USER_STATUS_QL_ADMIN,
} from "../dashboard/dashboardConstants";

export default {
  name: "FileUpload",

  setup() {
    const user = ref(null);
    const userid = ref(null);
    const router = useRouter(); // Get the router instance
    const isAdmin = ref(false);
    const userCredits = ref(0);
    const showPopup = ref(false);
    const popupMessage = ref("");
    const popupType = ref("");

    const subs = ref([]);

    window.onbeforeunload = function () {};

    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        user.value = currentUser;
        userid.value = currentUser.uid;

        const userRef = doc(db, "users", userid.value);
        getDoc(userRef)
          .then((doc) => {
            if (doc.exists()) {
              const userProfile = doc.data();

              if (
                userProfile.status === USER_STATUS_QL_ADMIN ||
                userProfile.status === USER_STATUS_CNY_ADMIN
              ) {
                isAdmin.value = true;

                userCredits.value = userProfile.credits;
                getSubordinates(userid.value);
              } else {
                // redirect to dashboard
                router.push("/dashboard"); // Use this to access the Vue instance
              }
            } else {
              console.error("No such document!");
            }
          })
          .catch((error) => {
            console.error("Error getting document:", error);
          });
      } else {
        user.value = null;
        userid.value = null;
        showAlert("Please login to use data submission.");
      }
    });

    const getSubordinates = async (user_id) => {
      try {
        const userDocRef = doc(db, "users", user_id);
        const subordinatesCollectionRef = collection(
          userDocRef,
          "subordinates"
        ); // Access the subordinates subcollection
        const subordinatesSnapshot = await getDocs(subordinatesCollectionRef);

        const subordinates = subordinatesSnapshot.docs.map((doc) => doc.data());

        for (const subordinate of subordinates) {
          const userRef = doc(db, "users", subordinate.uid);
          const userSub = await getDoc(userRef);

          if (userSub.data()) {
            let leName = userSub.data().firstname
              ? userSub.data().firstname
              : "";
            leName += userSub.data().lastname
              ? " " + userSub.data().lastname
              : "";

            const userToAdd = {
              uid: subordinate.uid,
              name: leName,
              email: userSub.data().email,
              jobFunction: userSub.data().jobFunction,
              credits: userSub.data().credits,
              creditsToAdd: null,
              creditsToRemove: null,
            };

            subs.value.push(userToAdd);
          }
        }

        subs.value.sort((a, b) => a.name - b.name);

        // Order uploadHistory by date desc
      } catch (error) {
        console.error(error);
        return [];
      }
    };

    const getIdToken = async () => {
      const firebaseUID = await user.value.getIdToken(/* forceRefresh */ true);
      return firebaseUID;
    };

    const showAlert = (message) => {
      popupMessage.value = message;
      popupType.value = "alert";
      showPopup.value = true;
    };

    const closePopup = () => {
      showPopup.value = false;
      //resetComponent();
    };

    const addEmployee = async () => {
      const email = document.getElementById("email").value;

      // check if email valid

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email || email.length === 0) {
        showAlert("Email is required..");

        return null;
      } else if (!emailPattern.test(email)) {
        showAlert("Invalid email address.");

        return null;
      }

      // Check if email known in db

      const userFound = await getUserByEmail(email);
      if (userFound) {
        console.log("User found:", userFound);

        if (userFound.uid === userid.value) {
          showAlert("You cannot add yourself.");
          return null;
        }

        if (
          userFound.status === USER_STATUS_CNY_ADMIN ||
          userFound.status === USER_STATUS_QL_ADMIN
        ) {
          showAlert("You cannot add an admin.");
          return null;
        }
      } else {
        showAlert("User not found.");
        return null;
      }

      const userRef = doc(db, "users", userFound.uid);
      setDoc(
        userRef,
        { invitedBy: userid.value },
        { merge: true }
      ).catch((error) => {
        console.error("Error sending invite: ", error);
        return null;
      });
      popupMessage.value = `Invite sent successfully.`;
      popupType.value = "success";
      showPopup.value = true;
    };

    // Function to fetch user by email
    const getUserByEmail = async (email) => {
      const usersCollection = collection(db, "users"); // Replace with your actual collection name
      const q = query(usersCollection, where("email", "==", email)); // Create a query to find the user

      try {
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          console.log("No matching documents found.");
          return null; // No user found with that email
        }

        // Assuming each email is unique, you can return the first matching document
        let userData = null;
        querySnapshot.forEach((doc) => {
          userData = { id: doc.id, ...doc.data() }; // Include document ID
        });

        return userData; // Return the user data
      } catch (error) {
        console.error("Error getting documents: ", error);
        return null; // Handle errors appropriately
      }
    };

    const dispatchCredits = () => {
      let adminCredits = 0;

      // check if Nan
      if (
        subs.value.filter(
          (sub) =>
            isNaN(sub.creditsToAdd) ||
            isNaN(sub.creditsToRemove) ||
            sub.creditsToAdd < 0 ||
            sub.creditsToRemove < 0
        ).length > 0
      ) {
        showAlert("Please only enter positive numbers.");
        return null;
      }

      // check if add/remove
      if (
        subs.value.filter(
          (sub) => sub.creditsToAdd > 0 && sub.creditsToRemove > 0
        ).length > 0
      ) {
        showAlert(
          "You cannot add and remove at the same time for a given user."
        );
        return null;
      }

      // check if remove too much
      if (
        subs.value.filter(
          (sub) =>
            sub.creditsToRemove > sub.credits ||
            (!sub.credits && sub.creditsToRemove > 0)
        ).length > 0
      ) {
        showAlert("You cannot remove more credits than there are.");
        return null;
      }

      // Check if enough credits
      const sumCreditsToAdd = subs.value.reduce((acc, sub) => {
        return acc + Number(sub.creditsToAdd);
      }, 0);

      const sumCreditsToRemove = subs.value.reduce((acc, sub) => {
        return acc + Number(sub.creditsToRemove);
      }, 0);

      if (userCredits.value - sumCreditsToAdd + sumCreditsToRemove < 0) {
        showAlert("Not enough credits.");
        return null;
      }

      // Do all credits removal and adds
      for (const sub of subs.value) {
        let creditsRemoved = sub.creditsToRemove;
        let creditsAdded = sub.creditsToAdd;
        let newCredits = 0;

        if (creditsAdded > 0) {
          newCredits = sub.credits
            ? Number(sub.credits) + Number(creditsAdded)
            : Number(creditsAdded);
        }

        if (sub.credits && creditsRemoved > 0) {
          newCredits = sub.credits
            ? Number(sub.credits) - Number(creditsRemoved)
            : Number(creditsRemoved);
        }

        // subordinate earns or loses credits
        try {
          const userRef = doc(db, "users", sub.uid);
          setDoc(userRef, { credits: newCredits }, { merge: true }).catch(
            (error) => {
              console.error("Error changing credits: ", error);
              return null;
            }
          );
          sub.credits
            ? (sub.credits += Number(creditsAdded))
            : (sub.credits = Number(creditsAdded));
          sub.credits -= Number(creditsRemoved);
          adminCredits += Number(creditsRemoved);
          adminCredits -= Number(creditsAdded);
        } catch (error) {
          console.error("Error changing credits: ", error);
        }
      }

      // admin earns credits
      console.log(userCredits.value);

      let newCredits = userCredits.value
        ? Number(userCredits.value) + Number(adminCredits)
        : Number(adminCredits);

      try {
        const userRef = doc(db, "users", userid.value);
        setDoc(userRef, { credits: newCredits }, { merge: true }).catch(
          (error) => {
            console.error("Error changing credits: ", error);
            return null;
          }
        );

        userCredits.value = newCredits;

        const event = new CustomEvent("creditsChanged", {
          detail: newCredits,
        });
        document.dispatchEvent(event);
      } catch (error) {
        console.error("Error changing credits: ", error);
      }

      // creditsToAdd and creditsToRemove to 0
      for (const sub of subs.value) {
        sub.creditsToAdd = null;
        sub.creditsToRemove = null;
      }
    };

    return {
      user,
      userid,

      showPopup,
      popupMessage,
      popupType,

      closePopup,

      isAdmin,

      addEmployee,
      dispatchCredits,
      subs,
    };
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-group {
  align-content: center;

  width: 75%;
  margin: auto;
}

.sub {
  width: 100%;
  margin-top: 2rem;
  table {
    font-size: small;
  }

  .input-credits {
    width: 5rem;
  }
}
</style>
