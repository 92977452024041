import { auth, db } from "@/firebase";
import { doc, collection, getDocs } from "firebase/firestore";

export const getUploadHistory = async (user_id, category) => {
  try {
    let uploadHistory = [];

    const userDocRef = doc(db, "users", user_id);
    const submissionsCollectionRef = collection(userDocRef, "submissions"); // Access the submissions subcollection
    const submissionsSnapshot = await getDocs(submissionsCollectionRef);

    const submissions = submissionsSnapshot.docs.map((doc) => doc.data());

    for (const submission of submissions) {
      if (category && submission.category != category) {
        continue;
      }
      const submissionTimestamp = new Date(submission.timestamp.seconds * 1000);
      const updatedInfo = {
        name: submission.firstname + " " + submission.lastname,
        sampleID: submission.sampleID ? submission.sampleID : "N/A",
        type: submission.type,
        date:
          submissionTimestamp.toLocaleDateString() + " " + submissionTimestamp.toLocaleTimeString(),
      };
      const uploadElement = {
        ...submission,
        ...updatedInfo,
      };
      uploadHistory.push(uploadElement);
    }
    uploadHistory.sort((a, b) => b.timestamp - a.timestamp);
    return uploadHistory;
  } catch (error) {
    console.error(error);
    return [];
  }
};
