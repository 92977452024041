// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAxMR8_g2xBrxJiV7nxVAjmxlAjpHk-KE8",
  authDomain: "quantumlife-10796.firebaseapp.com",
  projectId: "quantumlife-10796",
  storageBucket: "quantumlife-10796.appspot.com",
  messagingSenderId: "533797886714",
  appId: "1:533797886714:web:458b5fc2690b1b82d50743",
  measurementId: "G-QCGRR9YBZQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const storage = getStorage(app);

export { auth, db, storage };