<template>
    <div>
        <NavbarStyleThree />
        <MainBanner />
        <!-- <Support /> -->
        <Step />
        <Business />
        <!-- <SuccessStory /> -->
        <Payment />
        <GetStarted />
        <Footer /> 
    </div>
</template>

<script>
import NavbarStyleThree from '../layout/NavbarStyleThree'
import MainBanner from '../genomics/MainBanner.vue'
// import MainBanner from '../financial-services/MainBanner'
import Step from '../genomics/Step.vue'
import Support from '../genomics/Support'
import Business from '../genomics/Business'
// import SuccessStory from '../genomics/SuccessStory'
import Payment from '../genomics/Payment'
import GetStarted from '../genomics/GetStarted'
import Footer from '../layout/Footer'

export default {
    name: 'GenomicsPage',
    components: {
        NavbarStyleThree,
        MainBanner,
        Step,
        Support,
        Business,
        // SuccessStory,
        Payment,
        GetStarted,
        Footer,
    }
}
</script>