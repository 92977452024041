<template>
    <div :class="['navbar-area currency-transfer-provider-navbar', {'is-sticky': isSticky}]">
        <div class="vibion-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/images/logo.png" alt="logo" style="width: 200px; height: auto; object-fit: contain;">
                        <img src="../../assets/images/black-logo.png" alt="logo" style="width: 200px; height: auto; object-fit: contain;">
                    </router-link>
                    
                    <div 
                        class="navbar-toggler"
                        @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ 'active': button_active_state }"
                        v-on:click="button_active_state = !button_active_state"
                    >
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </div>

                    <div class="collapse navbar-collapse" :class="{ show: active }">
                        <ul class="navbar-nav">
                            <li class="nav-item"><router-link to="/" class="nav-link">Home</router-link></li>

                            <li class="nav-item"><a href="#" class="nav-link">Products <i class="fas fa-chevron-down"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/Gen-Decoder" class="nav-link">
                                            Gen-Decoder
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/Epi-Insight" class="nav-link">
                                            Epi-Insight
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/Epi-Tracker" class="nav-link">
                                            Epi-Tracker
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item"><router-link to="/science" class="nav-link">Science</router-link></li>
                            <li class="nav-item"><router-link to="/about-us" class="nav-link">About Us</router-link></li>

                            <li class="nav-item" v-if="isLoggedIn">
                                <router-link to="/contact" class="nav-link">Contact Us</router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="others-options d-flex align-items-center">
                        <!-- Show login/register buttons if not logged in -->
                        <div v-if="!isLoggedIn" class="d-flex align-items-center">
                            <div class="options-item">
                                <router-link to="/login" class="login-btn">Log in</router-link>
                            </div>
                            <div class="options-item">
                                <router-link to="/register" class="btn btn-primary">Register</router-link>
                            </div>
                        </div>

                        <!-- Show user profile icon and dropdown if logged in -->
                        <div 
                            v-else 
                            class="user-profile-dropdown position-relative" 
                            @mouseover="toggleDropdown(true)" 
                            @mouseleave="toggleDropdown(false)"
                        >
                            <img src="@/assets/images/profile.png" alt="User Profile" class="user-icon" />
                            <div v-if="dropdownActive" class="dropdown-menu show">
                                <!-- Turn off the data submission functions termporarily -->
                                <!-- <router-link to="/data-submit" class="dropdown-item">Data Submission</router-link> -->
                                <router-link to="/dashboard" class="dropdown-item">Dashboard</router-link>
                                <a href="#" class="dropdown-item" @click="logout">Log Out</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { auth } from '@/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

export default {
    name: 'NavbarStyleThree',
    data(){
        return {
            isSticky: false,
            active: false,
            button_active_state: false,
            dropdownActive: false,
            isLoggedIn: false, // Initially set to false
            userProfile: null // Store user profile information here
        }
    },

    methods: {
        toggleDropdown(status) {
            this.dropdownActive = status;
        },
        logout() {
            signOut(auth).then(() => {
                this.isLoggedIn = false;
                this.userProfile = null;
                alert("You have successfully logged out");
                this.$router.push("/");
            }).catch((error) => {
                console.error("Sign out error:", error);
            });
        },

        checkAuthStatus() {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    this.isLoggedIn = true;
                    this.userProfile = user;
                } else {
                    this.isLoggedIn = false;
                    this.userProfile = null;
                }
            });
        }
    },

    mounted(){
        const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            if(scrollPos >= 100){
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        });

        this.checkAuthStatus();
    }
}
</script>

<style scoped>
.user-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000; /* Ensure the dropdown appears on top */
    display: none;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item {
    padding: 8px 16px;
    display: block;
    color: #333;
    text-decoration: none;
}

.dropdown-item:hover {
    background-color: #f7f7f7;
}
</style>
