<template>
    <div>
        <NavbarStyleThree />
        <PageTitle />
        <div class="currency-transfer-provider-with-background-color">
            <Contact />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleThree from '../layout/NavbarStyleThree'
import PageTitle from '../contact/PageTitle'
import Contact from '../contact/Contact'
import Footer from '../layout/Footer'

export default {
    name: 'ContactPage',
    components: {
        NavbarStyleThree,
        PageTitle,
        Contact,
        Footer,
    }
}
</script>
