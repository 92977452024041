<template>
    <div class="report-area">
        <!-- <div class="container"> -->
            <div class="section-title">
                <h2>Sample Report Viewer</h2>
            </div>
            <h3>English Version</h3>            
            <!-- List of PDF files -->
            <div class="report-types">
                <div
                    v-for="(file, index) in pdfFiles"
                    :key="index"
                    class="report-card"
                >
                    <img :src="file.image" alt="image">
                    <h3>{{ file.name }}</h3>
                    <!-- <p>{{ file.description }}</p> -->
                    <button class="button" @click="openPdfViewer(file.name, file.url)">View Report</button>
                </div>
            </div>
            <!-- List of Chinese PDF files -->
            <h3>中文版</h3> 
            <div class="report-types">
                <div
                    v-for="(file, index) in pdfFilesChinese"
                    :key="index"
                    class="report-card"
                >
                    <img :src="file.image" alt="image">
                    <h3>{{ file.name }}</h3>
                    <!-- <p>{{ file.description }}</p> -->
                    <button class="button" @click="openPdfViewer(file.name, file.url)">View Report</button>
                </div>
            </div>
        <!-- </div> -->


        <!-- PDF Viewer Modal -->
        <div v-if="showPdfViewer" class="pdf-viewer-modal" @click.self="closePdfViewer">
            <div class="pdf-viewer-window">
                <div class="pdf-viewer-nav">
                    <h2 class="pdftitle">{{ pdftitle }}</h2>
                    <button @click="closePdfViewer" class="close">&times;</button>
                </div>
                <div class="pdf-viewer-content">
                    <p v-if="!loaded">Report is Loading...</p>
                    <pdfvuer 
                        :src="pdfdata" 
                        v-for="i in numPages"
                        :key="i"
                        :id="i"
                        :page="i"
                        :annotation="true"
                        :resize="true"
                        @link-clicked="handle_pdf_link"
                    >
                        <template slot="loading">
                            Loading...
                        </template>
                    </pdfvuer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import pdfvuer from 'pdfvuer';

export default {
    name: 'SampleReportViewer',
    components: {
        pdfvuer,
    },
    methods: {
        handle_pdf_link(params) {
            const page = this.$el.getElementsByID(params.page);
            if (page) {
                page.scrollIntoView();
            }
        },
    },
    setup() {
        const pdfFiles = ref([
            { 
                name: 'Gen-Decoder Sample Report', 
                url: 'samples/wgs_sample_report_20241114.pdf',
                // description: 'This is a sample report for demonstration purposes.',
                image: require('@/assets/images/dna1.jpg')
            },
            { 
                name: 'Epi-Insight Sample Report', 
                url: 'samples/epic_age_sample.pdf',
                // description: 'This is a sample report for demonstration purposes.',
                image: require('@/assets/images/dna1.jpg') 
            },
            { 
                name: 'Epi-Tracker Sample Report', 
                url: 'samples/epic_index_sample.pdf', 
                // description: 'This is a sample report for demonstration purposes.',
                image: require('@/assets/images/dna1.jpg')
            },
        ]);
        const pdfFilesChinese = ref([
            { 
                name: 'Gen-Decoder报告样例', 
                url: 'samples/wgs_sample_report_20241114.pdf',
                // description: 'This is a sample report for demonstration purposes.',
                image: require('@/assets/images/dna1.jpg')
            },
            { 
                name: 'Epi-Insight报告样例', 
                url: 'samples/Epi-Insight_ch.pdf',
                // description: 'This is a sample report for demonstration purposes.',
                image: require('@/assets/images/dna1.jpg') 
            },
            { 
                name: 'Epi-Tracker报告样例', 
                url: 'samples/Epi-Tracker_ch.pdf', 
                // description: 'This is a sample report for demonstration purposes.',
                image: require('@/assets/images/dna1.jpg')
            },
        ]);
        const showPdfViewer = ref(false);
        const pdftitle = ref('');
        const pdfdata = ref(null);
        const numPages = ref(0);
        const loaded = ref(false);
        

        const openPdfViewer = (name, url) => {
            pdftitle.value = name;
            showPdfViewer.value = true;
            loaded.value = false;
            pdfdata.value = pdfvuer.createLoadingTask(url);
            pdfdata.value.then(pdf => {
                loaded.value = true;
                numPages.value = pdf.numPages;
            });
        };

        const closePdfViewer = () => {
            showPdfViewer.value = false;
            pdfdata.value = null;
            pdftitle.value = '';
        };

        return {
            pdftitle,
            pdfdata,
            numPages,
            loaded,
            pdfFiles,
            pdfFilesChinese,
            showPdfViewer,
            openPdfViewer,
            closePdfViewer,
        };
    },
};      

</script>


<style scoped lang="scss">
@keyframes popUp {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.report-area {
    margin: 30px 0;
    text-align: center;
    justify-content: center;
    width: 100%;

    .section-title {
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 50px;
        text-align: center;
    }

    .report-types {
        display: flex;
        justify-content: space-evenly;
        max-width: 1100px;
        margin: 0 auto;

        .report-card {
            // flex: none;
            width: 33.3333%;
            display: inline-block;
            text-align: center;
            background: none;
            border: 1px solid transparent;
            padding: 30px;
            transition: all .3s;
            border-radius: 16px;

            &:hover {
                background-color: white;
                border-color: color-mix(in oklab, #ededed, white 3%);
                box-shadow: -1px 3px 10px 0 rgb(0 0 0 / 6%);
            }

            img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
                border-radius: 14px;
                height: auto;
            }

            h3 {
                font-size: 1.1rem;
                font-weight: 600;
                margin-bottom: 10px;
                text-align: center;
                margin: 16px 0;
            }

            p {
                font-size: 1.1rem;
                color: #a2a5b9;
                text-align: center;
                margin: 0 auto 20px;
            }

            .button {
                margin: 0 auto;
                min-width: 140px;
                font-weight: 500;
                padding: 8px 22px;
                height: 38px;
                line-height: 1.1;
                font-size: .95rem;
                border-radius: 9999px;
                border-width: 1px;
                justify-content: center;
                text-align: center;
                white-space: nowrap;
                
                --base-shadow: rgb(0 0 0 / 12%);
                --primary: oklch(66.67% .146 173.07deg);
                --primary--color-invert: color-mix(in oklab, var(--primary), white 90%);
                --primary-shadow-from: hsl(from var(--primary) h s l / 42%);
                --primary-shadow-to: hsl(from var(--primary) h s l / 20%);
                --primary-box-shadow: 0 14px 26px -12px var(--primary-shadow-from), 0 4px 23px 0 var(--base-shadow), 0 8px 10px -5px var(--primary-shadow-to) !important;
                box-shadow: var(--primary-box-shadow);
                color: var(--primary--color-invert);
                background-color: var(--primary);
                border-color: transparent;
                
            }
        }
    }

    .pdf-viewer-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;      
        animation: popUp 0.3s ease-in-out;
        // overflow: hidden;

        .pdf-viewer-window {
            background-color: white;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            width: 90%;
            max-width: 1200px;
            height: 100%;
            display: flex;
            flex-direction: column;
            border: 1px solid black;

            .pdf-viewer-nav {
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 20px;
                background-color: #f0f0f0;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                border-bottom: 1px solid #e0e0e0;

                .pdftitle {
                    margin: 0;
                    font-size: 18px;
                }

                .close  {
                    background: none;
                    border: none;
                    font-size: 28px;
                    cursor: pointer;
                    color: #333;
                }
            }

            .pdf-viewer-content {
                flex-grow: 1;
                padding: 20px;
                overflow-y: auto;
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                -o-user-select: none;


                p {
                    font-size: 1.1rem;
                    color: #a2a5b9;
                    text-align: center;
                    margin: 0 auto 20px;
                }
            }
        }
    }
}
</style>