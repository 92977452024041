<template>
    <div class="ctp-services-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Our Features</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/services/mobile-payment.svg" alt="image">
                            </div>
                            Streamlined Data Analysis
                        </h3>
                        <p>Simplify complex omics data analysis with our intuitive platform, designed for ease of use without requiring coding expertise.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/services/warning.svg" alt="image">
                            </div>
                            Integrated Multi-Omics Approach
                        </h3>
                        <p>Combine genetic, epigenetic, and risk assessment data using Quantum Genomics, Quantum Epigenomics, and Quantum Index for a holistic view of health and aging.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/services/fluctuation.svg" alt="image">
                            </div>
                            Advanced Reporting Tools
                        </h3>
                        <p>Generate comprehensive, easy-to-understand reports that translate detailed omics data into actionable insights for personalized health strategies.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="../../assets/images/currency-transfer-provider/services/contract.svg" alt="image">
                            </div>
                            High Security Standards
                        </h3>
                        <p>Ensure the confidentiality and integrity of sensitive data with our platform, built to comply with leading healthcare data protection regulations.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>