<template>
    <div class="login-area">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-image">
                    <img src="@/assets/images/main-banner3.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="login-form">
                                <div class="logo">
                                    <router-link to="/">
                                        <img src="../../assets/images/Longevity.omics-logo.png" alt="image">
                                    </router-link>
                                </div>

                                <h3>Welcome back</h3>
                                <p>New to Longevity Omics? <router-link to="/register">Register</router-link></p>

                                <!-- Success and Error Messages -->
                                <div v-if="successMessage" class="alert-success">{{ successMessage }}</div>
                                <div v-if="errorMessage" class="alert-danger">{{ errorMessage }}</div>

                                <form @submit.prevent="signIn" v-if="status === 'login'">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" placeholder="Your email address" class="form-control" v-model="email">
                                        <span v-if="emailError" class="error">{{ emailError }}</span>
                                    </div>

                                    <div class="form-group">
                                        <input type="password" name="password" id="password" placeholder="Your password" class="form-control" v-model="password">
                                        <span v-if="passwordError" class="error">{{ passwordError }}</span>
                                    </div>

                                    <button type="submit" class="btn btn-primary">Login</button>
                                    
                                    <div class="forgot-password">
                                        <router-link to="/password-reset">
                                            Forgot Password?
                                        </router-link>
                                    </div>
                                </form>
                                <div class="verification-window" v-else-if="status === 'emailVerification'">
                                    <div class="box">
                                        <div class="box-content">
                                            <img src="@/assets/images/verification.svg" alt="email verfication image" class="box-image">
                                            <h3>Please verify your account</h3>
                                            <p>Click the link in the email we sent you to verify your account.</p>
                                            <button type="button" @click="emailVerificationResend">Resend Verification Email</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { auth, db } from '@/firebase';
    import { signInWithEmailAndPassword } from 'firebase/auth';
    import { doc, setDoc, getDoc } from 'firebase/firestore';
    import { emailVerification, emailVerificationResend } from '../Register/emailService';


    export default {
        name: 'LoginArea',
        data() {
            return {
                email: '',
                password: '',
                emailError: '',
                passwordError: '',
                successMessage: '',
                errorMessage: '',
                status: 'login',
                interval: null,
                router_link: '/'
            }
        },
        watch: {
            email(value) {
                if (!value || value.length === 0) {
                    this.emailError = 'Email is required';
                } 
                else if (!/\S+@\S+\.\S+/.test(value)) {
                    this.emailError = 'Please enter a valid email';
                }
                else {
                    this.emailError = '';
                }
            },
            password(value) {
                if (!value) {
                    this.passwordError = 'Password is required';
                } else {
                    this.passwordError = '';
                }
            }
        },
        methods: {
            formValidate() {
                if (!this.email || !this.password) {
                    this.emailError = !this.email ? 'Email is required' : '';
                    this.passwordError = !this.password ? 'Password is required' : '';
                    return false;
                }

                if (!/\S+@\S+\.\S+/.test(this.email)) {
                    this.emailError = 'Please enter a valid email';
                    return false;
                }

                return true;
            },

            emailVerificationResend() {
                emailVerificationResend(this);
            },

            beforeDestroy() {
                if (this.interval) {
                    clearInterval(this.interval);
                }
            },

            beforeRouterLeave(to, from, next) {
                if (this.interval) {
                    clearInterval(this.interval);
                }
                next();
            },

            signIn() {
                if (!this.formValidate()) {
                    return;
                }
                signInWithEmailAndPassword(auth, this.email, this.password)
                    .then((userCredential) => {
                        // Signed in 
                        const user = userCredential.user;                        
                        if (!user.emailVerified) {
                            this.errorMessage = 'Your email is not verified. Please verify your email to login.';
                            this.successMessage = '';
                            this.status = 'emailVerification';
                            // Send email verification
                            emailVerification(user, this);
                        } else {
                            const userRef = doc(db, 'users', user.uid);
                            getDoc(userRef).then((docSnap) => {
                                if (docSnap.exists() && docSnap.data().emailVerified !== true) {
                                    setDoc(userRef, { emailVerified: true }, { merge: true });
                                }
                                else if (!docSnap.exists()) {
                                    throw new Error('User not found in database with uid: ' + user.uid);
                                }
                            });
                            this.successMessage = 'You have successfully logged in. Redirecting to home page...';
                            this.errorMessage = '';
                            setTimeout(() => {
                                this.$router.push(this.router_link);
                            }, 2000);
                        }
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log(errorCode, errorMessage);
                        this.errorMessage = "Invalid email or password. Please try again.";
                        this.successMessage = '';
                    });
            }
        }
    }
</script>