<template>
    <div class="ctp-money-transfer-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>How Does Longevity.Omics Work?</h2>
            </div>
            <div style="margin-top: 80px;"> </div>
            <WorkPath />
            <!-- <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Upload Your Data</h3>
                        <div class="image">
                            <img src="../../assets/images/mainpage/upload.png" alt="image">
                            <div class="number">01</div>
                        </div>
                        <p>Easily upload your genetic or epigenetic data files directly to our secure platform. Our system supports various data formats, ensuring a smooth and straightforward data integration process.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Submit Analysis Tasks</h3>
                        <div class="image">
                            <img src="../../assets/images/mainpage/analysis.png" alt="image">
                            <div class="number">02</div>
                        </div>
                        <p>Once your data is uploaded, choose from our range of analysis options tailored to your specific needs. Submit your analysis tasks, and our advanced algorithms will do the rest, providing you with detailed, actionable insights.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Receive Analysis Reports</h3>
                        <div class="image">
                            <img src="../../assets/images/mainpage/report.png" alt="image">
                            <div class="number">03</div>
                        </div>
                        <p>After the analysis is complete, receive comprehensive reports directly in your account. These reports will offer deep insights and actionable recommendations, empowering you to make informed decisions for personalized health management.</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import WorkPath from './WorkPath.vue'
export default {
    name: 'HowItWork',
    components: {
        WorkPath
    }
}
</script>