<template>
    <div class="login-area">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-image">
                    <img src="../../assets/images/login-bg.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="login-form">
                                <div class="logo">
                                    <router-link to="/">
                                        <img src="../../assets/images/Longevity.omics-logo.png" alt="image">
                                    </router-link>
                                </div>

                                <h3>Reset Your Password</h3>
                                <!-- <p>New to Quantum Life? <router-link to="/register">Register</router-link></p> -->

                                <!-- Success and Error Messages -->
                                <div v-if="successMessage" class="alert-success">{{ successMessage }}</div>
                                <div v-if="errorMessage" class="alert-danger">{{ errorMessage }}</div>

                                <form @submit.prevent="reset">
                                    <div class="form-group">
                                        <input type="password" name="password" id="password" placeholder="New password" class="form-control" v-model="password">
                                        <span v-if="passwordError" class="error">{{ passwordError }}</span>
                                    </div>

                                    <div class="form-group">
                                        <input type="password" name="password_confirm" id="password_confirm" placeholder="Confirm password" class="form-control" v-model="password_confirm">
                                        <span v-if="passwordConfirmError" class="error">{{ passwordConfirmError }}</span>
                                    </div>

                                    <button type="submit" class="btn btn-primary">Reset Password</button>
                                </form>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { auth } from '@/firebase';
    import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';


    export default {
        name: 'LoginArea',
        data() {
            return {
                password: '',
                passwordError: '',
                password_confirm: '',
                passwordConfirmError: '',
                successMessage: '',
                errorMessage: '',
                mode: '',
                oobCode: '',
                router_link: '/login'
            }
        },
        watch: {
            password(value) {
                if (!value) {
                    this.passwordError = "Password is required.";
                } else if (value.length < 6) {
                    this.passwordError = "Password must be at least 6 characters.";
                } else {
                    this.passwordError = "";
                }
            },
            password_confirm(value) {
                if (!value) {
                    this.passwordConfirmError = "Password confirmation is required.";
                } else if (value !== this.password) {
                    this.passwordConfirmError = "Passwords do not match.";
                } else {
                    this.passwordConfirmError = "";
                }
            }
        },
        created() {
            const urlParams = new URLSearchParams(window.location.search);
            this.mode = urlParams.get('mode');
            this.oobCode = urlParams.get('oobCode');
        },

        methods: {
            formValidate() {
                if (!this.password) {
                    this.passwordError = "Password is required.";
                } else if (this.password.length < 6) {
                    this.passwordError = "Password must be at least 6 characters.";
                } else {
                    this.passwordError = "";
                }

                if (!this.password_confirm) {
                    this.passwordConfirmError = "Password confirmation is required.";
                } else if (this.password_confirm !== this.password) {
                    this.passwordConfirmError = "Passwords do not match.";
                } else {
                    this.passwordConfirmError = "";
                }

                return !this.passwordError && !this.passwordConfirmError;
            },

            reset() {
                if (!this.formValidate()) {
                    return;
                }
                verifyPasswordResetCode(auth, this.oobCode).then((email) => {
                    const accountEmail = email;
                    const newPassword = this.password;
                    // Save the new password
                    confirmPasswordReset(auth, this.oobCode, newPassword)
                        .then((resp) => {
                            // Password reset has been confirmed and new password updated.
                            this.successMessage = 'Password reset successful. Redirecting to login page...';
                            this.errorMessage = '';
                            setTimeout(() => {
                                this.$router.push(this.router_link);
                            }, 3000);
                        })
                        .catch((error) => {
                            // Error occurred during confirmation. The code might have expired or the
                            // password is too weak.
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.error(errorCode, errorMessage);
                            this.errorMessage = errorMessage;
                            this.successMessage = '';
                        });
                }).catch((error) => {
                    // Invalid or expired action code. Ask user to try to reset the password
                    // again.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.error(errorCode, errorMessage);
                    this.errorMessage = errorMessage;
                    this.successMessage = '';
                });
            }
        }
    }
</script>

<style scoped lang="scss">
.reset-info {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
    text-align: left;
}


</style>