<template>
    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <h1>Unlock the Secrets of Aging with Epi-Insight</h1>
                        <p><b>Transforming complex DNA methylation data into insightful reports on epigenetic aging</b></p>
                        <router-link v-if="!isLoggedIn" to="/register" class="btn btn-primary">
                            Get Started
                        </router-link>
                        <router-link v-else to="/dashboard" class="btn btn-primary">
                            Go To Dashboard
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { auth } from '@/firebase';
import { onAuthStateChanged } from 'firebase/auth';

export default {
name: 'MainBanner',
data() {
    return {
    isLoggedIn: false, // Initially set to false
    }
},

methods: {
    checkAuthStatus() {
    onAuthStateChanged(auth, (user) => {
        if (user) {
        this.isLoggedIn = true;
        } else {
        this.isLoggedIn = false;
        }
    });
    }
},
mounted() {
    this.checkAuthStatus();
}
}
</script>


<style scoped>
.main-banner {
    position: relative; /* Needed to position the pseudo-element */
    background-image: url('@/assets/images/dna1.jpg'); /* Path to your background image */
    background-size: cover; /* Cover the entire area of the element */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    height: 100vh; /* Set the height of the banner */
}

.main-banner::before {
    content: ""; /* Required for pseudo-elements */
    position: absolute; /* Position relative to the parent */
    top: 0; /* Cover from top */
    left: 0; /* Cover from left */
    right: 0; /* Cover to the right */
    bottom: 0; /* Cover to the bottom */
    background-color: rgba(0, 0, 0, 0.3); /* Black with 50% opacity */
    z-index: 1; /* Ensure it's above the background image but below content */
}

.main-banner-content {
    position: relative; /* Ensure the content is above the overlay */
    z-index: 2; /* Higher z-index to be on top of the overlay */
}
</style>