<template>
    <div class="ctp-protec-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Quick Tutorial</h2>
                <video 
                    :src="videoURL" 
                    :poster="posterURL"
                    width="1000"
                    controls
                    class="instruction-video"
                    >Your browser does not support the video tag.</video>
            </div>
        </div>  
    </div>
</template>

<script>
export default {
    data() {
        return {
            videoURL: 'instruction_en.mp4',
            posterURL: 'poster.png'
        }
    }
}

</script>

<style scoped>
.instruction-video {
  text-align: center;
  margin-top: 80px;
}
</style>