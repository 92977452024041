<template>
    <div class="how-it-works-area ptb-70">
        <div class="container">
            <div class="section-title">
                <h2>Comprehensive Aging Report</h2>
                <br>
                <p>Our report provides six major components of aging, which can give you a comprehensive view of your aging process.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img src="../../assets/images/Epi-Insight/aging.png" alt="image">

                        <h3>Epigenetic Age</h3>
                        <p>The epigenetic age is a measure of the biological age of an individual based on the epigenetic marks on their DNA.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img src="../../assets/images/Epi-Insight/pace.png" alt="image">

                        <h3>Pace of Aging</h3>
                        <p>The pace of aging is calculated by DunedinPACE, which is a measure of how quickly an individual's biological age is increasing.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img src="../../assets/images/Epi-Insight/acce.png" alt="image">

                        <h3>Aging Acceleration and Aging Rate</h3>
                        <p>The aging acceleration and aging rate are calculated by comparing biological age to chronological age, which reflects how quickly an individual is aging biologically.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img src="../../assets/images/Epi-Insight/curve.png" alt="image">

                        <h3>Aging Percentile</h3>
                        <p>Aging percentile is a measure of how an individual's biological aging rate compares to the general population.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img src="../../assets/images/Epi-Insight/system.png" alt="image">

                        <h3>System Age</h3>
                        <p>System age is a measure of organs and systems specific age, including heart age, brain age, liver age, immune age, etc.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img src="../../assets/images/Epi-Insight/change.png" alt="image">

                        <h3>Changes Across Reports</h3>
                        <p>Changes across reports reflect how your aging process is progressing over time.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HowItWorks'
    }
</script>

<style scoped>
.single-how-it-works img{
    width: 40%;
}
</style>

