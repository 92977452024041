<template>
    <div class="main-banner">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <div class="main-banner-content">
              <!-- <h1>Longevity.Omics</h1> -->
              <img src="../../assets/images/Longevity.omics-logo-white and blue.png" alt="logo" style="width: 600px; height: auto; object-fit: contain;">
              <p>One-stop solutions from raw data to clinical reports</p>
              <!-- Conditional rendering based on authentication status -->
              <router-link v-if="!isLoggedIn" to="/register" class="btn btn-primary">
                Get Started
              </router-link>
              <router-link v-else to="/dashboard" class="btn btn-primary">
                Go To Dashboard
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { auth } from '@/firebase';
import { onAuthStateChanged } from 'firebase/auth';

export default {
name: 'MainBanner',
data() {
    return {
    isLoggedIn: false, // Initially set to false
    }
},

methods: {
    checkAuthStatus() {
    onAuthStateChanged(auth, (user) => {
        if (user) {
        this.isLoggedIn = true;
        } else {
        this.isLoggedIn = false;
        }
    });
    }
},

mounted() {
    this.checkAuthStatus();
}
}
</script>
