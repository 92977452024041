<template>
    <div class="coming-soon-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Feature Coming Soon</h2>
                <div class="bar"></div>
                <p>Quantum Life Notification</p>
            </div>

            <div class="coming-soon-content">
                <h2>Our website is currently under construction. Stay tuned for our exciting new site! Subscribe now to get notified when we launch.</h2>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'ComingSoonArea'
    }
</script>