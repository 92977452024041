<template>
  <div class="main-sidebar">
    <div class="sidebar-brand">
      <a href="/">
        <img
          src="@/assets/images/Longevity.omics-logo-o.png"
          alt="QuantumLife Logo"
        />
      </a>
    </div>
    <div class="sidebar-inner">
      <div class="naver"></div>
      <ul class="icon-menu">
        <li>
          <a
            tabindex="0"
            role="button"
            data-content="Dashboard"
            href="/dashboard"
            :class="{ 'is-active': isActive('/dashboard') }"
            @click="handleClick('/dashboard', $event)"
          >
            <iconify-icon class="iconify sidebar-svg" icon="lucide:activity">
            </iconify-icon>
          </a>
        </li>
        <li>
          <a
            tabindex="0"
            role="button"
            data-content="Samples"
            href="/sample-reports"
            :class="{ 'is-active': isActive('/sample-reports') }"
            @click="handleClick('/sample-reports', $event)"
          >
            <iconify-icon
              class="iconify sidebar-svg"
              icon="mdi:report-box-outline"
            ></iconify-icon>
          </a>
        </li>

        <!-- Turn off the data submission if user is not active  -->
        <li
          v-if="
            userStatus === USER_STATUS_ACTIVE ||
            userStatus === USER_STATUS_CNY_ADMIN ||
            userStatus === USER_STATUS_QL_ADMIN
          "
        >
          <a
            tabindex="0"
            role="button"
            data-content="Submission"
            href="/data-submit"
            :class="{ 'is-active': isActive('/data-submit') }"
            @click="handleClick('/data-submit', $event)"
          >
            <iconify-icon
              class="iconify sidebar-svg"
              icon="iconamoon:cloud-upload-light"
            ></iconify-icon>
          </a>
        </li>

        <!-- Turn off the admin panel if user is not admin  -->
        <li
          v-if="
            userStatus === USER_STATUS_CNY_ADMIN ||
            userStatus === USER_STATUS_QL_ADMIN
          "
        >
          <a
            tabindex="0"
            role="button"
            data-content="Cny Admin"
            href="/cnyadmin"
            :class="{ 'is-active': isActive('/cnyadmin') }"
            @click="handleClick('/cnyadmin', $event)"
          >
            <iconify-icon
              class="iconify sidebar-svg"
              icon="iconamoon:star-light"
            ></iconify-icon>
          </a>
        </li>

        <!-- Turn off the admin panel if user is not admin  -->
        <li v-if="userStatus === USER_STATUS_QL_ADMIN">
          <a
            tabindex="0"
            role="button"
            data-content="Admin"
            href="/qladmin"
            :class="{ 'is-active': isActive('/qladmin') }"
            @click="handleClick('/qladmin', $event)"
          >
            <iconify-icon
              class="iconify sidebar-svg"
              icon="iconamoon:star-light"
            ></iconify-icon>
          </a>
        </li>

        <li>
          <a
            tabindex="0"
            role="button"
            data-content="Contact"
            href="/contact-side"
            :class="{ 'is-active': isActive('/contact-side') }"
            @click="handleClick('/contact-side', $event)"
          >
            <iconify-icon
              class="iconify sidebar-svg"
              icon="iconamoon:email"
            ></iconify-icon>
          </a>
        </li>
      </ul>
      <ul class="bottom-menu">
        <li>Credits :</li>
        <li>{{ userCredits }}</li>
        <li>
          <div class="profile">
            <a
              role="button"
              tabindex="0"
              class="dropdown-trigger"
              aria-haspopup="true"
            >
              <div class="v-avatar" @click="toggleDropdown">
                <img :src="avatar" alt="avatar" class="avatar" />
              </div>
              <div class="dropdown-menu" role="menu" v-if="isDropdownVisible">
                <div class="dropdown-content">
                  <div class="dropdown-head">
                    <div class="v-avatar is-large">
                      <img :src="avatar" alt="" class="avatar" />
                    </div>
                    <div class="meta">
                      <span>{{ companyName }}</span>
                    </div>
                  </div>
                  <a href="/dashboard" role="menuitem" class="dropdown-item">
                    <div class="icon">
                      <iconify-icon
                        class="iconify"
                        icon="mdi:account"
                      ></iconify-icon>
                    </div>
                    <div class="meta">
                      <span>Profile</span>
                      <span>View your profile</span>
                    </div>
                  </a>
                  <!-- Turn off the data submission function termporarily -->
                  <!-- <hr class="dropdown-divider">
                                    <a href="/data-submit" role="menuitem" class="dropdown-item">
                                        <div class="icon">
                                            <iconify-icon class="iconify" icon="mingcute:briefcase-2-line"></iconify-icon>
                                        </div>
                                        <div class="meta">
                                            <span>Projects</span>
                                            <span>View your projects</span>
                                        </div>
                                    </a> -->
                  <!-- TODO: Finish settings then turn on this -->
                  <!-- <hr class="dropdown-divider">
                                    <a href="/settings" role="menuitem" class="dropdown-item">
                                        <div class="icon">
                                            <iconify-icon class="iconify" icon="ep:setting"></iconify-icon>
                                        </div>
                                        <div class="meta">
                                            <span>Settings</span>
                                            <span>Account settings</span>
                                        </div>
                                    </a> -->
                  <hr class="dropdown-divider" />
                  <div class="dropdown-item is-button">
                    <button
                      type="button"
                      class="button"
                      role="menuitem"
                      aria-hidden="false"
                      @click="logout"
                    >
                      <span class="icon">
                        <iconify-icon class="iconify" icon="mdi:logout">
                        </iconify-icon>
                      </span>
                      <span> Logout </span>
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { auth, db } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged, signOut } from "firebase/auth";
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_CNY_ADMIN,
  USER_STATUS_NEW,
  USER_STATUS_QL_ADMIN,
} from "./dashboardConstants";

export default {
  name: "SideBar",
  data() {
    return {
      isLoggedIn: false,
      userProfile: null,
      companyName: "Company Name",
      avatar: require("@/assets/images/dna3.jpg"),
      isDropdownVisible: false,
      userStatus: "",
      USER_STATUS_ACTIVE: USER_STATUS_ACTIVE,
      USER_STATUS_CNY_ADMIN: USER_STATUS_CNY_ADMIN,
      USER_STATUS_QL_ADMIN: USER_STATUS_QL_ADMIN,
      userCredits: 0,
    };
  },

  methods: {
    logout() {
      signOut(auth)
        .then(() => {
          this.isLoggedIn = false;
          this.userProfile = null;
          alert("You have successfully logged out");
          this.$router.push("/");
        })
        .catch((error) => {
          console.error("Sign out error:", error);
        });
    },

    checkAuthStatus() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.isLoggedIn = true;
          const userRef = doc(db, "users", user.uid);
          getDoc(userRef)
            .then((doc) => {
              if (doc.exists()) {
                this.userProfile = doc.data();
                this.companyName = this.userProfile.companyName
                  ? this.userProfile.companyName
                  : "Company Name";
                this.avatar = this.userProfile.avatar
                  ? this.userProfile.avatar
                  : require("@/assets/images/dna3.jpg");

                this.userStatus = this.userProfile.status
                  ? this.userProfile.status
                  : USER_STATUS_NEW;

                this.userCredits = this.userProfile.credits
                  ? this.userProfile.credits
                  : 0;
              } else {
                console.error("No such document!");
              }
            })
            .catch((error) => {
              console.error("Error getting document:", error);
            });
        } else {
          this.isLoggedIn = false;
          this.userProfile = null;
          this.companyName = "Company Name";
          this.avatar = require("@/assets/images/dna3.jpg");
          // alert("You are not logged in. Please log in to access this page.");
          this.$router.push("/login");
        }
      });
    },

    isActive(link) {
      return window.location.pathname === link;
    },

    handleClick(link, event) {
      if (this.isActive(link)) {
        event.preventDefault();
      }
    },

    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },

    handleClickOutside(event) {
      if (this.isDropdownVisible && !this.$el.contains(event.target)) {
        this.isDropdownVisible = false;
      }
    },

    handleChangeUserCredits(event) {
      this.userCredits = event.detail ? event.detail : 0;
    },
  },
  mounted() {
    this.checkAuthStatus();
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("creditsChanged", this.handleChangeUserCredits);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
