<template>
    <form class="feedback-wrapper" novalidate>
        <div class="inner-wrapper">
            <div class="feedback-area">
            <!-- <div class="container"> -->
                <div class="feedback-title">
                    <h2>Feedback Form</h2>
                </div> 
                <div class="types">
                    <div class="columns">
                        <div
                            v-for="(type, index) in feedbackTypes"
                            :key="index"
                            class="column is-4"
                        >
                            <div class="feedback-card">
                                <img :src="type.image" alt="image">
                                <h3>{{ type.title }}</h3>
                                <p>{{ type.description }}</p>
                                <div class="button-wrapper">
                                    <button 
                                        class="button is-primary v-button is-rounded is-bold is-elevated" 
                                        @click="goToStepTwo(type.title)">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: 'Feedback',
    data() {
        return {
            feedbackTypes: [
                {
                    title: 'UI/UX Design Suggestions',
                    description: 'Provide suggestions for improving our platform interface and your experience',
                    image: require('@/assets/images/feedback/type-1.svg'),
                },
                {
                    title: 'Feature Advice/Request',
                    description: 'Request new features or provide advice on existing features (reports)',
                    image: require('@/assets/images/feedback/type-2.svg'),
                },
                {
                    title: 'Report a Bug',
                    description: 'Report a bug or issue you encountered while using our platform',
                    image: require('@/assets/images/feedback/type-3.svg'),
                },
            ],
        };
    },
    methods: {
        goToStepTwo(type) {
            this.$router.push({
                name: 'FeedbackStepTwo',
                params: {
                    type
                },
            });
        },
    },
};
</script>