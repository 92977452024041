<template>
    <div class="get-started-area ptb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="get-started-title">
                        <h2>Ready to get started?</h2> 

                        <router-link to="/register" class="btn btn-primary">Sign Up Now</router-link>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="get-started-content">
                        <p>Monitor Your Health Evolution with Epi-Tracker.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GetStarted'
    }
</script>