<template>
  <div>
    <Preloader v-if="isLoading" />
    <div class="view-wrapper" style="min-height: 10vh;">
      <div class="page-content-wrapper is-size-default">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- <div class="title-wrap">
              <h1 class="title">
                <span>Dashboard</span>
              </h1>
            </div> -->
          </div>
          <div class="personal-dashboard">
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="dashboard-header">
                  <div class="v-avatar is-xl">
                    <img :src="avatar" alt="" class="avatar" />
                  </div>
                  <div class="user-meta">
                    <h3 class="title is-4 is-narrow is-bold">
                      Welcome back,
                      {{ userProfile ? userProfile.firstname : "" }}
                    </h3>
                    <p class="light-text">It's really nice to see you again</p>

                    <h4>
                      {{ managerProfile ? managerProfile.companyName : null }}
                    </h4>
                  </div>
                  <div class="user-action">
                    <div v-if="status === USER_STATUS_WAITLIST">
                      <h3 class="title is-4 is-narrow">
                        You are in the waitlist
                      </h3>
                      <p class="light-text">
                        Please wait for us to approve your account
                      </p>
                    </div>
                    <div
                      class="join-waitlist"
                      v-if="status === USER_STATUS_NEW"
                    >
                      <h3 class="title is-4 is-narrow">
                        Want to start using our services?
                      </h3>
                      <p class="light-text">
                        You can click the button below to get into our waiting
                        list
                      </p>
                      <button
                        class="button v-button is-primary is-elevated is-rounded"
                        @click="goToWaitlist()"
                      >
                        Join Waitlist
                      </button>
                    </div>

                    <div
                      v-if="
                        status === USER_STATUS_ACTIVE ||
                        status === USER_STATUS_CNY_ADMIN ||
                        status === USER_STATUS_QL_ADMIN
                      "
                    >
                      <h3 class="credits" style="text-align: center;">
                        You have
                        <br />
                        <b>{{ userProfile.credits ? userProfile.credits : 0 }}</b>
                        <br />
                        credits remaining.
                      </h3>
                    </div>
                  </div>
                  <div class="cta h-hidden-tablet-p">
                    <div class="inverted-text">
                      <iconify-icon
                        class="iconify"
                        icon="pepicons-pencil:crown"
                      ></iconify-icon>
                      <p class="white-text">
                        Have any comments or encounter any issues?
                      </p>
                      <a href="/contact-side" class="link inverted-text"
                        >Contact Us</a
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="dashboard-header"
                  v-if="
                    userProfile &&
                    userProfile.invitedBy &&
                    userProfile.invitedBy.length > 0
                  "
                >
                  You received an invitation from {{ inviterInfo }} to join
                  their company. Do you accept ?

                  <button
                    class="button v-button is-primary is-elevated is-rounded"
                    @click="acceptInvite('Y')"
                  >
                    Yes
                  </button>
                  <button
                    class="button v-button is-elevated is-rounded"
                    @click="acceptInvite('N')"
                  >
                    No
                  </button>
                </div>
              </div>
              <div
                v-if="
                  status === USER_STATUS_ACTIVE ||
                  status === USER_STATUS_CNY_ADMIN ||
                  status === USER_STATUS_QL_ADMIN
                "
                class="dashboard-container"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, db } from "@/firebase";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import Preloader from "../layout/Preloader.vue";
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_WAITLIST,
  USER_STATUS_NEW,
  USER_STATUS_QL_ADMIN,
  USER_STATUS_CNY_ADMIN,
} from "./dashboardConstants";
import { getUploadHistory } from "../data-submit/uploadHistory";
// import Profile from '../profile/Profile'

export default {
  name: "DashboardArea",
  data() {
    return {
      isLoading: true,
      isUserLoading: true,
      userProfile: null,
      companyName: "",
      role: "",
      email: "",
      uid: "",
      avatar: "",
      status: "",
      uploadHistory: [],
      USER_STATUS_NEW: USER_STATUS_NEW,
      USER_STATUS_WAITLIST: USER_STATUS_WAITLIST,
      USER_STATUS_ACTIVE: USER_STATUS_ACTIVE,
      USER_STATUS_QL_ADMIN: USER_STATUS_QL_ADMIN,
      USER_STATUS_CNY_ADMIN: USER_STATUS_CNY_ADMIN,

      currentPage: 1,
      itemsPerPage: 5,
      uploadHistory: [],
      paginatedUploads: [],

      inviterProfile: null,
      inviterInfo: "",

      managerProfile: null,
    };
  },
  components: {
    Preloader,
  },
  methods: {
    checkAuthStatus() {
      this.isUserLoading = true;
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // this.isLoggedIn = true;

          const userRef = doc(db, "users", user.uid);
          this.getUserInfos(user, userRef);
        } else {
          // this.isLoggedIn = false;
          this.userProfile = null;
          this.companyName = "Company Name";
          this.avatar = require("@/assets/images/dna3.jpg");
          console.error("No user is signed in.");
          // this.$router.push("/login");
        }
      });
      this.isUserLoading = false;
    },

    async getUploads() {
      this.uploadHistory = [];
      this.paginatedUploads = [];
      this.uploadHistory = await getUploadHistory(this.uid);

      this.paginatedUploads = this.uploadHistory.slice(0, this.itemsPerPage);
    },

    async getUserInfos(user, userRef) {
      if (!user.uid) {
        console.error("User ID is not initialized.");
        return; // Early exit if uid is not set
      }

      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        this.userProfile = userDoc.data();
        this.companyName = this.userProfile.companyName
          ? this.userProfile.companyName
          : "Company Name";
        this.role = this.userProfile.role ? this.userProfile.role : "Role";
        this.email = this.userProfile.email ? this.userProfile.email : "Email";
        this.uid = this.userProfile.uid ? this.userProfile.uid : "UID";
        this.status = this.userProfile.status
          ? this.userProfile.status
          : USER_STATUS_NEW;

        this.avatar = this.userProfile.avatar
          ? this.userProfile.avatar
          : require("@/assets/images/dna3.jpg");

        this.getUploads();

        // Display inviter info if needed

        if (
          this.userProfile.invitedBy &&
          this.userProfile.invitedBy.length > 0
        ) {
          const inviterRef = doc(db, "users", this.userProfile.invitedBy);
          const inviterDoc = await getDoc(inviterRef);

          if (inviterDoc.exists()) {
            this.inviterProfile = inviterDoc.data();

            this.inviterInfo =
              this.inviterProfile.firstname +
              " " +
              this.inviterProfile.lastname;

            if (
              this.inviterProfile.companyName &&
              this.inviterProfile.companyName != undefined &&
              this.inviterProfile.companyName.length > 0
            ) {
              this.inviterInfo += " - " + this.inviterProfile.companyName;
            }

            this.inviterInfo += " (" + this.inviterProfile.email + ")";
          }
        }

        // Get accountManager info
        if (this.userProfile.accountManager) {
          const managerRef = doc(db, "users", this.userProfile.accountManager);
          const managerDoc = await getDoc(managerRef);
          if (managerDoc.exists()) {
            this.managerProfile = managerDoc.data();

            console.log(this.managerProfile);
          }
        }
      } else {
        console.error("No such document!");
      }
    },

    goToWaitlist() {
      this.$router.push("/join-waitlist");
    },

    goTo(link) {
      this.$router.push(link);
    },

    acceptInvite(answer) {
      if (answer === "Y") {
        // Create a subordinate collection on the inviter profile, and add the current user
        const inviterRef = doc(db, "users", this.userProfile.invitedBy); // Get the inviter's document reference
        const subordinatesCollectionRef = collection(
          inviterRef,
          "subordinates"
        ); // Reference to the subordinate collection

        // Create a new document for the current user in the subordinate collection
        const newSubordinateRef = doc(subordinatesCollectionRef); // Generate a new document reference

        console.log("newSubordinateRef", newSubordinateRef);

        setDoc(newSubordinateRef, {
          uid: this.userProfile.uid,
          createdAt: new Date(), // Optional: Add a timestamp
        })
          .then(() => {
            console.log("Subordinate added successfully.");
          })
          .catch((error) => {
            console.error("Error adding subordinate: ", error);
          });

        // Change status to active and add manager
        try {
          const userRef = doc(db, "users", this.userProfile.uid);
          setDoc(
            userRef,
            {
              status: USER_STATUS_ACTIVE,
              accountManager: this.userProfile.invitedBy,
            },
            { merge: true }
          ).catch((error) => {
            console.error("Error changing invite status: ", error);
            return null;
          });

          this.userProfile.invitedBy = null;
        } catch (error) {
          console.error("Error changing invite status: ", error);
        }

        this.status = USER_STATUS_ACTIVE;
      }

      // remove invite from invitee either way

      try {
        const userRef = doc(db, "users", this.userProfile.uid);
        setDoc(userRef, { invitedBy: null }, { merge: true }).catch((error) => {
          console.error("Error changing invite status: ", error);
          return null;
        });

        this.userProfile.invitedBy = null;
      } catch (error) {
        console.error("Error changing invite status: ", error);
      }
    },
  },
  created() {
    this.checkAuthStatus();
    if (!this.isUserLoading) {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    }
  },
};
</script>
