<template>
    <div>
        <SideBar />
        <JoinWaitlistArea />
    </div>
</template>

<script>
import SideBar from '../dashboard/SideBar.vue';
import JoinWaitlistArea from '../waitlist/JoinWaitlistArea.vue';

export default {
    name: 'SampleReports',
    components: {
        SideBar,
        JoinWaitlistArea
    }
}

</script>