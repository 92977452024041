<template>
    <div class="ctp-page-banner-area">
        <div class="container">
            <div class="ctp-page-banner-content">
                <h3>About us</h3>
                <ul class="list">
                    <li>
                        <router-link to="/">Home</router-link>
                    </li>
                    <li>About us</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>

<style scoped>
.ctp-page-banner-area {
    background-image: url('@/assets/images/main-banner3.jpg'); /* Update the path as per your directory structure */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    color: #fff; /* Adjust text color for better visibility against background */
}

.container {
    width: 90%;
    margin: 0 auto;
}

.ctp-page-banner-content h3 {
    font-size: 2.5rem; /* Adjust font size as needed */
}

.list {
    list-style: none;
    padding: 0;
}

.list li {
    display: inline-block;
    margin-right: 10px;
    font-size: 1.2rem; /* Adjust font size as needed */
}

.list li a {
    color: #fff; /* Ensure link color contrasts well against the background */
    text-decoration: none;
}
</style>