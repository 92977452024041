<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Quantum Life Privacy Policy</h2>
                <div class="bar"></div>
                <p>INFORMATION & NOTICES</p>
            </div>

            <div class="privacy-policy-content">
                <h3>1. Introduction</h3>
                <p>Quantum Life Limited, together with its affiliates ("we", "us", "our", or “Quantum Life”), are committed to protecting personal data in accordance with the Hong Kong Personal Data (Privacy) Ordinance (the “PDPO”). By accessing our website, mobile application, or any of our services (collectively, the "Services"), you agree to be bound by these Terms of Service ("Terms"), including this Privacy Policy (“Privacy Policy”). These Terms govern your access to and use of our Services and any information, or other services provided through our platforms.
                </p>
                <p>We may amend this Privacy Policy at any time and for any reason. The updated version will be available by following the “Privacy” link on our website homepage. You should check the Privacy Policy regularly for changes.
                </p>
                <p>In this Privacy Policy, “personal data” means any data:
                </p>
                <p>(a) relating directly or indirectly to a living individual;</p>
                <p>(b) from which it is practicable for the identity of the individual to be directly or indirectly ascertained; and</p>
                <p>(c) in a form in which access to or processing of the data is practicable.</p>
                <p>The types of personal data we collect from you will depend on the circumstances in which that information is collected. If the personal data that we request from you is not provided, we may be unable to provide or continue to provide products and services to you.
                </p>
            </div>

            <div class="privacy-policy-content">
                <h3>2. Information We Collect</h3>
                <h4>2.1 Personal Information</h4>
                <ul>
                    <li><strong>Information You Provide</strong>: We collect information you provide when you use our Services, including when you create an account, contact us, or participate in a consultation, including your identity, contact details and/or biometric data. </li>
                    <li><strong>Health Information</strong>: We collect health information provided to us, including medical history, symptoms, and other health-related data.
                    </li>
                </ul>

                <h4>2.2 Technical and Usage Information</h4>
                <ul>
                    <li><strong>Log Data</strong>: We collect log data when you use our Services, including your IP address, browser type, and operating system.</li>
                    <li><strong>Cookies</strong>: We use cookies and similar tracking technologies to track activity on our Services and hold certain information.</li>
                </ul>
            </div>

            <div class="privacy-policy-content">
                <h3>3. How We Use Your Information</h3>
                <ul>
                    <li><strong>Manage Account</strong>:We manage your registration as a user of the Service.</li>
                    <li><strong>Communicate with You</strong>: We use your information to communicate with you, including sending service-related messages and updates.</li>
                    <li><strong>Provide Services</strong>: We use your information to provide medical consultations, information, and other services through our platform.</li>
                    <li><strong>Improve Services</strong>: We use your information to improve our Services, including by analyzing user behavior and trends.</li>
                    <li><strong>Research and Development</strong>: We use your information for research and development purposes, including to develop new features and services.</li>
                    <li><strong>Legal Compliance</strong>: We use your information to comply with legal obligations and protect our rights.</li>
                    <li><strong>Health Management</strong>: We provide healthcare services and consultations.</li>
                    <li><strong>Perform Contractual Obligations</strong>: We fulfill our obligations to you as outlined in our <a href="/terms-of-service">Terms of Service</a>.</li>
                    <li><strong>Direct marketing</strong>:We do use some of the personal data we collect to send marketing material and special offers to the intended recipients via telemarketing, electronic means, direct mail or such other appropriate means, but only in accordance with the rules about direct marketing contained in the PDPO.</li>
                </ul>
            </div>

            <div class="privacy-policy-content">
                <h3>4. How We Share Your Information</h3>
                <p>Generally, we may disclose your personal data as necessary for:</p>
                <ul>
                    <li>the purpose for which you provided it to us;</li>
                    <li>purposes which are related to the purpose for which you provided it to us; and</li>
                    <li>any other purposes to which you</li>
                </ul>
                <h4>4.1 Service Providers</h4>
                <p>We may share your information with third-party providers, agents, contractors and advisors who provide administrative, communications, computer, payment, security or other services which assist us to carry out the above purposes  and/or help us provide our Services, including IT services, customer support, and data analysis. have consented, and to any other person to whom you have consented.
                </p>
                <h4>4.2 Legal Compliance</h4>
                <p>We may disclose your information to our legal and professional advisors, and to comply with legal obligations, protect our rights, and enforce our Terms of Service, including to government agencies and authorities as required by any law, regulation, rule or codes binding on us or our related companies.
                </p>
                <h4>4.3 Group and Business Transactions</h4>
                <p>We may disclose your information to our affiliates and/or research and business partners. If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.
                </p>
                <h4>4.4 Consent</h4>
                <p>We may share your information with your consent or at your direction.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>5. Security of Your Information</h3>
                <p>We use administrative, technical, and physical security measures to help protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or method of electronic storage is 100% secure. Where personal data is transferred to place(s) outside of Hong Kong in connection with such purposes, such place(s) may or may not offer the same or a similar level of personal data protection as in Hong Kong.
                </p>
            </div>

            <div class="privacy-policy-content">
                <h3>6. Data Retention</h3>
                <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
            </div>

            <div class="privacy-policy-content">
                <h3>7. Accessing and correcting / enquiring about your personal data</h3>
                <p>You may contact us to seek access to or seek to correct or enquire about the personal data which we hold about you. There are certain exemptions under the PDPO which may apply to personal data access and correction requests. We may require that you the person requesting access or correction provide suitable identification and we may charge a reasonable administration fee for complying with a data access request. 
                </p>
                <p>Requests for access to, to correct or to enquire about the personal data held by us should be addressed to: 
                </p>
                <p>Data Protection Officer</p>
                <p>Quantum Life Limited</p>
                <p>17W Building, HKSTP, NT, Hong Kong S.A.R.
                </p>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'PrivacyPolicyArea'
    }
</script>