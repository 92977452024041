<template>
    <div class="ctp-about-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-about-image"></div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ctp-about-content">
                        <span>About us</span>
                        <h4>AI-driven End-to-End Healthy Longevity Solution Platform</h4>
                        <p><strong>Quantum Life</strong> is a multi-omics-based longevity medicine platform dedicated to providing personalized healthy longevity solutions. Our services include personalized health insights and innovative therapeutic solutions designed to enhance quality of life and extend healthy lifespans.</p>
                        <p>Leveraging our proprietary technology, Longevity.Omics, and standardized protocols for multi-omics data generation, clinical services, interventional strategies, and AI-driven integrative data analysis, we generate comprehensive reports for our clients. These reports assess the current status of aging, fitness, and health conditions, predict healthy aging, disease, and longevity outcomes, and offer guidance on preventive and early intervention strategies, as well as further health improvement strategies.</p>
                        <h4>Mission</h4>
                        <p>To revolutionize longevity medicine by integrating multi-omics technologies and AI-driven data analysis, providing personalized health insights and innovative therapeutic solutions that enhance quality of life and extend healthy lifespan.</p>
                        <h4>Vision</h4>
                        <p>To be the global leader in longevity medicine, pioneering breakthroughs in genomics and multi-omics research to unlock the secrets of aging, develop therapeutics against aging and empower individuals to achieve optimal health and vitality throughout their lives.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>

<style scoped>
.ctp-about-image {
    background-image: url('@/assets/images/hkstp.jpeg'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%; /* Adjust height as needed */
    min-height: 300px; /* Example height, adjust according to your design */
}
</style>