<template>
    <div>
        <footer class="footer-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget">
                            <div class="logo">
                                <router-link to="/"><img src="../../assets/images/Longevity.omics-logo.png" alt="logo"  style="width: 200px; height: auto; object-fit: contain;"></router-link>
                                <p>One-stop services from raw data to clinical reports</p>
                            </div>

                            <ul class="social-links">
                                <!-- <li><a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.twitter.com/" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram"></i></a></li> -->
                                <li><p>Find us on LinkedIn: &emsp;<a href="https://www.linkedin.com/company/quantumlife2023/" target="_blank"><i class="fab fa-linkedin-in"></i></a></p></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget pl-5">
                            <h3>Our Platform</h3>

                            <ul class="list">
                                <li><router-link to="/">Home</router-link></li>
                                <li><router-link to="/Gen-Decoder">Gen-Decoder</router-link></li>
                                <li><router-link to="/Epi-Insight">Epi-Insight</router-link></li>
                                <li><router-link to="/Epi-Tracker">Epi-Tracker</router-link></li>
                                <!-- <li><router-link to="/features-2">Features</router-link></li>
                                <li><router-link to="/pricing">Our Pricing</router-link></li>
                                <li><router-link to="/blog-1">Latest News</router-link></li> -->
                                <li><router-link to="/science">Science</router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget">
                            <h3>Support</h3>

                            <ul class="list">
                                <!-- <li><router-link to="/faq">FAQ's</router-link></li> -->
                                <li><router-link to="/about-us">About Us</router-link></li>
                                <!-- <li><router-link to="/contact">Contact Us</router-link></li> -->
                                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                                <li><router-link to="/terms-of-service">Terms of Service</router-link></li>
                                <!-- <li><router-link to="/#">Community</router-link></li> -->
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget">
                            <h3>Address</h3>

                            <ul class="footer-contact-info">
                                <li><span>Location:</span> 17W Building, HKSTP, NT, Hong Kong </li>
                                <li><span>Email:</span> <a href="mailto:hello@vibion.com">info@quantumlife.tech</a></li>
                                <!-- <li><span>Phone:</span> <a href="tel:+321984754">+852 6096 7907</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="copyright-area">
                    <p>&copy; {{currentYear}} Quantum Life. All rights reserved Quantum Life Limited</p>
                </div>
            </div>

            <div class="map-image"><img src="../../assets/images/map.png" alt="map"></div>
        </footer>
        <!-- End Footer Area -->

        <div 
            @click="scrollToTop()" 
            :class="['back-to-top-btn', {'go-top': isTop}]"
        >
            <i class="fas fa-arrow-up"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data (){
        return {
            isTop: false,
            currentYear: new Date().getFullYear(),
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isTop = true
            } else {
                that.isTop = false
            }
        })
    }
}
</script>