<template>
  <Preloader v-if="isLoading" />
    <div class="view-wrapper">
        <div class="page-content-wrapper is-size-default">
            <div class="page-content is-relative">
                <div class="page-title has-text-centered">
                    <div class="title-wrap">
                        <h1 class="title">
                            <!-- <span>Contact Us</span> -->
                        </h1>
                    </div>
                </div>
                <form novalidate class="form-layout" @submit.prevent="submitForm">
                    <div class="form-outer">
                        <div class="form-header stuck-header">
                            <div class="form-header-inner">
                                <div class="left">
                                    <h3>Any Enquiries or Comments?</h3>
                                </div>
                                <div class="right">
                                    <div class="buttons">

                                        <button type="submit" class="button v-button is-primary is-raised">
                                            <span>Submit</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <div class="form-fieldset">
                                <div class="fieldset-heading">
                                    <h4>Person in Contact</h4>
                                    <p>This person will be the main contact for the waitlist.</p>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6">
                                        <div class="field">
                                            <label class="label">First Name <span>*</span></label>
                                            <div class="control has-icon">
                                                <input type="text" class="input" placeholder autocomplete="given-name" v-model="firstname" />
                                                <iconify-icon class="iconify form-icon" icon="lucide:user"></iconify-icon>
                                            </div>
                                            <p class="error" v-if="firstnameError">{{ firstnameError }}</p>
                                        </div>
                                    </div>
                                    <div class="column is-6">
                                        <div class="field">
                                            <label class="label">Last Name <span>*</span></label>
                                            <div class="control has-icon">
                                                <input type="text" class="input" placeholder autocomplete="family-name" v-model="surname" />
                                                <iconify-icon class="iconify form-icon" icon="lucide:user"></iconify-icon>
                                            </div>
                                            <p class="error" v-if="surnameError">{{ surnameError }}</p>
                                        </div>
                                    </div>
                                    
                                    <div class="column is-6">
                                        <div class="field">
                                            <label class="label">Phone Number<span>*</span></label>
                                            <div class="control has-icon">
                                                <input type="text" class="input" placeholder autocomplete="tel" v-model="phone_number" />
                                                <iconify-icon class="iconify form-icon" icon="lucide:phone"></iconify-icon>
                                            </div>
                                            <p class="error" v-if="phone_numberError">{{ phone_numberError }}</p>
                                        </div>
                                    </div>
                                    <div class="column is-12">
                                        <div class="field">
                                            <label class="label">Email <span>*</span></label>
                                            <div class="control has-icon">
                                                <input type="email" class="input" placeholder autocomplete="email" inputmode="email" v-model="email" />
                                                <iconify-icon class="iconify form-icon" icon="lucide:mail"></iconify-icon>
                                            </div>
                                            <p class="error" v-if="emailError">{{ emailError }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-fieldset">
                                <div class="fieldset-heading">
                                    <h4>Additional Information</h4>
                                    <p>Any additional information you would like to provide.</p>
                                </div>
                                <div class="column is-12">
                                    <div class="field">
                                        <label class="label">Subject <span>*</span></label>
                                        <div class="control has-icon">
                                            <input type="text" class="input" placeholder v-model="msg_subject" />
                                            <iconify-icon class="iconify form-icon" icon="lucide:mail"></iconify-icon>
                                        </div>
                                        <p class="error" v-if="msg_subjectError">{{ msg_subjectError }}</p>
                                    </div>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-12">
                                        <div class="field">
                                            <label class="label">Message</label>
                                            <div class="control">
                                                <textarea class="textarea" v-model="message" rows="4" placeholder="Tell us any details you'd like use to know..." autocomplete="off" autocapitalize="off" spellcheck="true"></textarea>
                                            </div>
                                            <p class="error" v-if="messageError">{{ messageError }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { auth, db } from "@/firebase";
import { doc, setDoc } from "firebase/firestore";

export default {
  name: "Contact",
  data() {
    return {
      userID: null,
      firstname: "",
      surname: "",
      email: "",
      phone_number: "",
      msg_subject: "",
      message: "",
      firstnameError: "",
      surnameError: "",
      emailError: "",
      phone_numberError: "",
      msg_subjectError: "",
      messageError: "",
    };
  },
  watch: {
    firstname(value) {
      if (!value || value.length === 0) {
        this.firstnameError = "First name is required";
      } else {
        this.firstnameError = "";
      }
    },
    surname(value) {
      if (!value || value.length === 0) {
        this.surnameError = "Surname is required";
      } else {
        this.surnameError = "";
      }
    },
    email(value) {
      if (!value || value.length === 0) {
        this.emailError = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        this.emailError = "Please enter a valid email";
      } else {
        this.emailError = "";
      }
    },
    phone_number(value) {
      if (!value || value.length === 0) {
        this.phone_numberError = "Phone number is required";
      } else {
        this.phone_numberError = "";
      }
    },
    msg_subject(value) {
      if (!value || value.length === 0) {
        this.msg_subjectError = "Subject is required";
      } else {
        this.msg_subjectError = "";
      }
    },
    message(value) {
      if (!value || value.length === 0) {
        this.messageError = "Message is required";
      } else {
        this.messageError = "";
      }
    },
  },
  methods: {
    formValidate() {
      if (
        !this.firstname ||
        !this.surname ||
        !this.email ||
        !this.phone_number ||
        !this.msg_subject ||
        !this.message
      ) {
        this.firstnameError = !this.firstname ? "First name is required" : "";
        this.surnameError = !this.surname ? "Surname is required" : "";
        this.emailError = !this.email ? "Email is required" : "";
        this.phone_numberError = !this.phone_number
          ? "Phone number is required"
          : "";
        this.msg_subjectError = !this.msg_subject ? "Subject is required" : "";
        this.messageError = !this.message ? "Message is required" : "";
        return false;
      }

      if (!/\S+@\S+\.\S+/.test(this.email)) {
        this.emailError = "Please enter a valid email";
        return false;
      }

      return true;
    },

    submitForm() {
      if (!this.formValidate()) {
        alert("At least one field is invalid, please double check");
        return;
      }
      const feedbackRef = doc(
        db,
        "feedbacks",
        this.userID,
        "feedbacks",
        new Date().toISOString()
      );
      const feedbackData = {
        firstname: this.firstname,
        surname: this.surname,
        email: this.email,
        phone_number: this.phone_number,
        msg_subject: this.msg_subject,
        message: this.message,
        created_at: new Date(),
      };

      setDoc(feedbackRef, feedbackData)
        .then(() => {
          // this.firstname = '';
          // this.surname = '';
          // this.email = '';
          // this.phone_number = '';
          // this.msg_subject = '';
          // this.message = '';
          // this.firstnameError = '';
          // this.surnameError = '';
          // this.emailError = '';
          // this.phone_numberError = '';
          // this.msg_subjectError = '';
          // this.messageError = '';
          alert("Feedback submitted successfully");

          window.location.reload();
        })
        .catch((error) => {
          console.error("Error submitting feedback: ", error);
          alert("Error submitting feedback");
        });
    },
  },
  created() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.userID = user.uid;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.error {
  color: red;
  font-weight: 400;
  margin-bottom: 15px;
}

.input-contact-side {
  height: 70px;
}

.form-control-side {
  height: 5rem;
  padding: 40px 100px 15px 20px;
  float: unset;
  border: none;
  font-size: 16.5px;

  font-weight: 600;

  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
</style>
