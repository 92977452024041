<template>
  <div>
    <SideBar />
    <QLAdminArea />
  </div>
</template>

<script>
import SideBar from "../dashboard/SideBar.vue";
import QLAdminArea from "../admin/QLAdminArea";

export default {
  name: "QLAdminPage",
  components: {
    SideBar,
    QLAdminArea,
  },
};
</script>
