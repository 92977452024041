<template>
  <div class="login-area">
    <div class="row m-0">
      <div class="col-lg-6 col-md-12 p-0">
        <div class="login-image">
          <img src="@/assets/images/main-banner3.jpg" alt="image" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12 p-0">
        <div class="login-content">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="login-form">
                <div class="logo">
                  <router-link to="/">
                    <img
                      src="../../assets/images/Longevity.omics-logo.png"
                      alt="image"
                    />
                  </router-link>
                </div>

                <h3>Welcome to</h3>
                <h3>Longevity.Omics Platform</h3>
                <p>
                  Already Have an Account?
                  <router-link to="/login" class="router-link"
                    >Log In</router-link
                  >
                </p>

                <!-- Success and Error Messages -->
                <div v-if="successMessage" class="alert-success">
                  {{ successMessage }}
                </div>
                <div v-if="errorMessage" class="alert-danger">
                  {{ errorMessage }}
                </div>

                <form
                  @submit.prevent="register"
                  v-if="status === 'register'"
                  novalidate
                >
                  <div class="form-group">
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="First Name"
                      class="form-control"
                      v-model="firstname"
                    />
                    <span v-if="firstnameError" class="error">{{
                      firstnameError
                    }}</span>
                  </div>

                  <div class="form-group">
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Last Name"
                      class="form-control"
                      v-model="lastname"
                    />
                    <span v-if="lastnameError" class="error">{{
                      lastnameError
                    }}</span>
                  </div>

                  <div class="form-group">
                    <div class="multiselect">
                      <div
                        class="multiselect-wrapper"
                        tabindex="0"
                        role="combobox"
                        @click.stop="toggleDropdown('jobLevel')"
                      >
                        <div
                          class="multiselect-placeholder"
                          v-if="jobLevel === ''"
                        >
                          Job Level
                        </div>
                        <div class="multiselect-single-label" v-else>
                          <div class="multiselect-single-label-text">
                            {{ jobLevel }}
                          </div>
                        </div>
                        <div class="multiselect-caret">
                          <iconify-icon
                            class="iconify"
                            icon="lucide:chevron-down"
                          ></iconify-icon>
                        </div>
                      </div>
                      <div
                        class="multiselect-dropdown is-hidden"
                        tabindex="1"
                        id="jobLevel"
                      >
                        <ul
                          class="multiselect-options"
                          id="multiselect-options"
                          role="listbox"
                        >
                          <div
                            class="multiselect-option"
                            role="option"
                            v-for="job in jobLevels"
                            :key="job"
                            @click.stop="handleSelectJobLevels(job)"
                            :class="{ 'is-selected': jobLevel === job }"
                          >
                            <span>{{ job }}</span>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <span v-if="jobLevelError" class="error">{{
                      jobLevelError
                    }}</span>
                  </div>

                  <div class="form-group">
                    <div class="multiselect">
                      <div
                        class="multiselect-wrapper"
                        tabindex="0"
                        role="combobox"
                        @click.stop="toggleDropdown('jobFunction')"
                      >
                        <div
                          class="multiselect-placeholder"
                          v-if="jobFunction === ''"
                        >
                          Job Function
                        </div>
                        <div class="multiselect-single-label" v-else>
                          <div class="multiselect-single-label-text">
                            {{ jobFunction }}
                          </div>
                        </div>
                        <div class="multiselect-caret">
                          <iconify-icon
                            class="iconify"
                            icon="lucide:chevron-down"
                          ></iconify-icon>
                        </div>
                      </div>
                      <div
                        class="multiselect-dropdown is-hidden"
                        tabindex="1"
                        id="jobFunction"
                      >
                        <ul
                          class="multiselect-options"
                          id="multiselect-options"
                          role="listbox"
                        >
                          <div
                            class="multiselect-option"
                            role="option"
                            v-for="func in jobFunctions"
                            :key="func"
                            @click.stop="handleSelectJobFunctions(func)"
                            :class="{ 'is-selected': jobFunction === func }"
                          >
                            <span>{{ func }}</span>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <span v-if="jobFunctionError" class="error">{{
                      jobFunctionError
                    }}</span>
                  </div>

                  <div class="form-group">
                    <div class="multiselect">
                      <div
                        class="multiselect-wrapper"
                        tabindex="0"
                        role="combobox"
                        @click.stop="toggleDropdown('country')"
                      >
                        <div
                          class="multiselect-placeholder"
                          v-if="country === ''"
                        >
                          Country/Region
                        </div>
                        <div class="multiselect-single-label" v-else>
                          <div class="multiselect-single-label-text">
                            {{ country }}
                          </div>
                        </div>
                        <div class="multiselect-caret">
                          <iconify-icon
                            class="iconify"
                            icon="lucide:chevron-down"
                          ></iconify-icon>
                        </div>
                      </div>
                      <div
                        class="multiselect-dropdown is-hidden"
                        tabindex="1"
                        id="country"
                      >
                        <ul
                          class="multiselect-options"
                          id="multiselect-options"
                          role="listbox"
                        >
                          <div
                            class="multiselect-option"
                            role="option"
                            v-for="c in countries"
                            :key="c"
                            @click.stop="handleSelectCountry(c)"
                            :class="{ 'is-selected': country === c }"
                          >
                            <span>{{ c }}</span>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <span v-if="countryError" class="error">{{
                      countryError
                    }}</span>
                  </div>

                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Your Work Email Address"
                      class="form-control"
                      v-model="email"
                    />
                    <span v-if="emailError" class="error">{{
                      emailError
                    }}</span>
                  </div>

                  <div class="form-group">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Your Work Phone Number"
                      class="form-control"
                      v-model="phone"
                    />
                    <span v-if="phoneError" class="error">{{
                      phoneError
                    }}</span>
                  </div>

                  <div class="form-group">
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      placeholder="Your Company's Name"
                      class="form-control"
                      v-model="companyName"
                    />
                    <span v-if="companyNameError" class="error">{{
                      companyNameError
                    }}</span>
                  </div>

                  <div class="form-group">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Set up your password"
                      class="form-control"
                      v-model="password"
                    />
                    <span v-if="passwordError" class="error">{{
                      passwordError
                    }}</span>
                  </div>

                  <div class="form-group">
                    <input
                      type="password"
                      name="password_confirm"
                      id="password_confirm"
                      placeholder="Confirm your password"
                      class="form-control"
                      v-model="password_confirm"
                    />
                    <span v-if="passwordConfirmError" class="error">{{
                      passwordConfirmError
                    }}</span>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="isRegistering"
                  >
                    {{ isRegistering ? "Signing up..." : "Sign up" }}
                  </button>

                  <div class="terms-policy">
                    <p>
                      By continuing, you are indicating that you accept our
                      <router-link to="/terms-of-service" class="router-link"
                        >Terms of Service</router-link
                      >
                      and
                      <router-link to="/privacy-policy" class="router-link"
                        >Privacy Policy</router-link
                      >
                    </p>
                  </div>
                </form>

                <div
                  class="verification-window"
                  v-else-if="status === 'emailVerification'"
                >
                  <div class="box">
                    <div class="box-content">
                      <img
                        src="@/assets/images/verification.svg"
                        alt="email verfication image"
                        class="box-image"
                      />
                      <h3>Please verify your account</h3>
                      <p>
                        Click the link in the email we sent you to verify your
                        account.
                      </p>
                      <button type="button" @click="emailVerificationResend">
                        Resend Verification Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, db } from "@/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { emailVerification, emailVerificationResend } from "./emailService.js";
import { jobLevels, jobFunctions, countries } from "../../services/jobInfo.js";
import { USER_STATUS_NEW } from "../dashboard/dashboardConstants.js";

export default {
  name: "RegisterForm",
  data() {
    return {
      firstname: "",
      lastname: "",
      jobLevel: "",
      jobFunction: "",
      country: "",
      email: "",
      phone: "",
      companyName: "",
      password: "",
      password_confirm: "",
      firstnameError: "",
      lastnameError: "",
      jobLevelError: "",
      jobFunctionError: "",
      emailError: "",
      phoneError: "",
      companyNameError: "",
      passwordError: "",
      passwordConfirmError: "",
      successMessage: "",
      errorMessage: "",
      status: "register",
      isRegistering: false,
      interval: null,
      router_link: "/login",
      jobLevels: jobLevels,
      jobFunctions: jobFunctions,
      countries: countries,
    };
  },
  watch: {
    firstname(value) {
      if (!value) {
        this.firstnameError = "First name is required";
      } else {
        this.firstnameError = "";
      }
    },
    lastname(value) {
      if (!value) {
        this.lastnameError = "Last name is required";
      } else {
        this.lastnameError = "";
      }
    },
    jobLevel(value) {
      if (!value) {
        this.jobLevelError = "Job level is required";
      } else if (!this.jobLevels.includes(value)) {
        this.jobLevelError = "Invalid job level";
      } else {
        this.jobLevelError = "";
      }
    },
    jobFunction(value) {
      if (!value) {
        this.jobFunctionError = "Job function is required";
      } else if (!this.jobFunctions.includes(value)) {
        this.jobFunctionError = "Invalid job function";
      } else {
        this.jobFunctionError = "";
      }
    },
    country(value) {
      if (!value) {
        this.countryError = "Country is required";
      } else if (!this.countries.includes(value)) {
        this.countryError = "Invalid country";
      } else {
        this.countryError = "";
      }
    },
    email(value) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value) {
        this.emailError = "Email is required.";
      } else if (!emailPattern.test(value)) {
        this.emailError = "Invalid email address.";
      } else {
        this.emailError = "";
      }
    },
    phone(value) {
      if (!value) {
        this.phoneError = "Phone number is required";
      } else {
        this.phoneError = "";
      }
    },
    companyName(value) {
      if (!value) {
        this.companyNameError = "Company name is required.";
      } else {
        this.companyNameError = "";
      }
    },
    password(value) {
      if (!value) {
        this.passwordError = "Password is required.";
      } else if (value.length < 6) {
        this.passwordError = "Password must be at least 6 characters.";
      } else {
        this.passwordError = "";
      }
    },
    password_confirm(value) {
      if (!value) {
        this.passwordConfirmError = "Password confirmation is required.";
      } else {
        this.passwordConfirmError = "";
      }
    },
  },
  methods: {
    toggleDropdown(id) {
      const dropdown = document.getElementById(id);
      dropdown.classList.toggle("is-hidden");
      const dropdowns = document.querySelectorAll(".multiselect-dropdown");
      dropdowns.forEach((item) => {
        if (item.id !== id) {
          item.classList.add("is-hidden");
        }
      });
    },

    handleSelectJobLevels(value) {
      if (!this.jobLevels.includes(value)) {
        this.jobLevels.push(value);
      }
      this.jobLevel = value;
      this.toggleDropdown("jobLevel");
    },

    handleSelectJobFunctions(value) {
      if (!this.jobFunctions.includes(value)) {
        this.jobFunctions.push(value);
      }
      this.jobFunction = value;
      this.toggleDropdown("jobFunction");
    },

    handleSelectCountry(value) {
      if (!this.countries.includes(value)) {
        this.countries.push(value);
      }
      this.country = value;
      this.toggleDropdown("country");
    },

    handleClickOutside(event) {
      const dropdowns = document.querySelectorAll(".multiselect-dropdown");
      dropdowns.forEach((dropdown) => {
        if (!dropdown.contains(event.target)) {
          dropdown.classList.add("is-hidden");
        }
      });
    },

    formValidate() {
      if (!this.firstname) {
        this.firstnameError = "First name is required";
      } else {
        this.firstnameError = "";
      }

      if (!this.lastname) {
        this.lastnameError = "Last name is required";
      } else {
        this.lastnameError = "";
      }

      if (!this.jobLevel) {
        this.jobLevelError = "Job level is required";
      } else if (!this.jobLevels.includes(this.jobLevel)) {
        this.jobLevelError = "Invalid job level";
      } else {
        this.jobLevelError = "";
      }

      if (!this.jobFunction) {
        this.jobFunctionError = "Job function is required";
      } else if (!this.jobFunctions.includes(this.jobFunction)) {
        this.jobFunctionError = "Invalid job function";
      } else {
        this.jobFunctionError = "";
      }

      if (!this.country) {
        this.countryError = "Country is required";
      } else if (!this.countries.includes(this.country)) {
        this.countryError = "Invalid country";
      } else {
        this.countryError = "";
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email) {
        this.emailError = "Email is required.";
      } else if (!emailPattern.test(this.email)) {
        this.emailError = "Invalid email address.";
      } else {
        this.emailError = "";
      }

      if (!this.phone) {
        this.phoneError = "Phone number is required";
      } else {
        this.phoneError = "";
      }

      if (!this.companyName) {
        this.companyNameError = "Company name is required.";
      } else {
        this.companyNameError = "";
      }

      if (!this.password) {
        this.passwordError = "Password is required.";
      } else if (this.password.length < 6) {
        this.passwordError = "Password must be at least 6 characters.";
      } else {
        this.passwordError = "";
      }

      if (!this.password_confirm) {
        this.passwordConfirmError = "Password confirmation is required.";
      } else if (this.password_confirm !== this.password) {
        this.passwordConfirmError = "Passwords do not match.";
      } else {
        this.passwordConfirmError = "";
      }

      return (
        !this.firstnameError &&
        !this.lastnameError &&
        !this.jobLevelError &&
        !this.jobFunctionError &&
        !this.countryError &&
        !this.emailError &&
        !this.phoneError &&
        !this.companyNameError &&
        !this.passwordError &&
        !this.passwordConfirmError
      );
    },

    createUser() {
      try {
        createUserWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            const user = userCredential.user;
            // Add user to the database
            setDoc(doc(db, "users", user.uid), {
              firstname: this.firstname,
              lastname: this.lastname,
              jobLevel: this.jobLevel,
              jobFunction: this.jobFunction,
              country: this.country,
              email: this.email,
              phone: this.phone,
              companyName: this.companyName,
              uid: user.uid,
              createdAt: new Date(),
              emailVerified: false,
              credits: 0,
              status: USER_STATUS_NEW,
            });

            // Send email verification
            emailVerification(user, this);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            this.successMessage = "";
            if (errorCode === "auth/email-already-in-use") {
              this.errorMessage = "Email already in use.";
            } else if (errorCode === "auth/invalid-email") {
              this.errorMessage = "Invalid email address.";
            } else if (errorCode === "auth/weak-password") {
              this.errorMessage = "Password is weak.";
            } else {
              console.log(error);
              this.errorMessage = errorMessage;
            }
          });
      } catch (error) {
        const errorMessage = error.message;
        this.successMessage = "";
        this.errorMessage = errorMessage;
      }
    },

    emailVerificationResend() {
      emailVerificationResend(this);
    },

    beforeDestroy() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },

    async register() {
      if (this.isRegistering) {
        return;
      }
      if (this.formValidate()) {
        this.isRegistering = true;
        try {
          await this.createUser();
        } catch (error) {
          // Handle any errors not caught in createUser
          console.log("Registration failed:", error);
          this.successMessage = "";
          this.errorMessage = "Registration failed. Please try again.";
        } finally {
            this.isRegistering = false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    this.beforeDestroy();
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener("click", this.handleClickOutside);
    this.beforeDestroy();
    next();
  },
};
</script>

<style lang="scss">
// .login-image{
//     background-image: url('@/assets/images/dna3.jpg') !important;
//     background-size: 100% 100% !important;
// }

.form-group {
  .multiselect {
    background-color: #ffffff !important;
    border: none;
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
    height: 50px;
    font: {
      family: "Raleway", sans-serif;
      size: 14px;
    }

    .multiselect-placeholder {
      color: #57647c !important;
      background-color: #ffffff !important;
    }
  }
}

.verification-window {
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    display: block;
    background-color: white;
    padding: 40px;
    border-radius: 6px;
    border: 1px solid color-mix(in oklab, #ededed, black 3%);
    transition: all 0.3s;
    color: #283252;
    position: relative;

    .box-content {
      text-align: center;

      .box-image {
        width: 100%;
        max-width: 220px;
        margin: 0 auto 8px;
        display: inline-block;
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 600;
        max-width: 320px;
        margin: 0 auto 8px;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        color: #a2a5b9;
        margin: 0 auto;
      }

      button {
        $primary: oklch(66.67% 0.146 173.07deg);

        margin: 0 auto;
        display: block;
        justify-content: center;
        margin-top: 20px;
        min-width: 160px;
        padding: 8px 22px;
        height: 38px;
        line-height: 1.1;
        font-size: 1rem;
        background-color: $primary;
        border-color: transparent;
        border: 1px solid transparent;
        border-radius: 4px;
        color: color-mix(in oklab, $primary, white 90%);
        cursor: pointer;

        font-size: 1rem;

        &:hover {
          opacity: 0.9;
          box-shadow: 0 14px 26px -12px hsl(from $primary h s l / 42%),
            0 4px 23px 0 rgb(0 0 0 / 12%),
            0 8px 10px -5px hsl(from $primary h s l / 20%) !important;
        }
      }
    }
  }
}
</style>
