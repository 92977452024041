<template>
    <div>
        <NavbarStyleThree />
        <PageTitle />
        <div class="currency-transfer-provider-with-background-color">
            <About />
            <!-- <Team />
            <Investors /> -->
            <Partner />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleThree from '../layout/NavbarStyleThree'
import PageTitle from '../about-us/PageTitle'
import About from '../about-us/About'
// import Team from '../about-us/Team'
// import Investors from '../about-us/Investors'
import Footer from '../layout/Footer'
import Partner from '../mainpage/Partner.vue'

export default {
    name: 'AboutUsPage',
    components: {
        NavbarStyleThree,
        PageTitle,
        About,
        // Team,
        // Investors,
        Partner,
        Footer,
    }
}
</script>
