<template>
    <div class="ctp-choose-area pb-100">
        <div class="container-fluid">
            <div class="row justify-content-center m-0">
                <div class="col-lg-6 col-md-12 p-0">
                    <!-- <div class="ctp-choose-image jarallax" data-jarallax='{"speed": 0.3}'></div> -->
                    <div class="image">
                        <img src="../../assets/images/dna6.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 p-0">
                    <div class="ctp-choose-content">
                        <h3>What is Gen-Decoder</h3>
                        <p>Gen-Decoder specializes in the analysis and interpretation of genetic data to uncover the secrets of longevity and healthy aging. By focusing on genetic predispositions linked to longevity, our service offers insights into how genes influence lifespan and the aging process. This targeted approach helps in identifying genetic factors that contribute to long-term health and vitality, providing a foundation for personalized anti-aging strategies.</p>
                        <br>
                        <br>
                        <h3>How it works</h3>
                        <p>Engage with Gen-Decoder to scientifically explore and enhance your health and longevity through precise genetic analysis. Your journey to informed health optimization begins here.</p>

                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="flaticon-check-mark"></i>
                                </div>
                                Step 1. DNA Data Upload
                            </h4>
                            <p>Initiate your exploration into genetic insights by uploading your DNA data to Gen-Decoder. We accommodate various data formats including raw sequencing data, mapped reads, or variant data from your sequencing provider. For a robust analysis that adheres to the highest scientific standards, we recommend submitting raw sequencing data, which allows our sophisticated global analysis pipeline to operate with maximum efficacy.</p>
                        </div>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="flaticon-check-mark"></i>
                                </div>
                                Step 2. DNA Data Preprocessing
                            </h4>
                            <p>Upon submission of your DNA data, Gen-Decoder assumes responsibility for its meticulous preprocessing. Our protocol includes stringent quality control measures, accurate mapping of reads, advanced variant calling techniques, and the exclusion of results with low confidence. This rigorous process culminates in the generation of a detailed genetic profile, encompassing over 35 million genetic variants. This profile serves as a comprehensive foundation for personalized genetic understanding.</p>
                        </div>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="flaticon-check-mark"></i>
                                </div>
                                Step 3. Health and Longevity Predispositions
                            </h4>
                            <p>Gen-Decoder offers an in-depth analysis of your DNA to identify genetic predispositions related to health and longevity. Our methodology is informed by extensive research across thousands of genetic loci and is both medically certified and validated against data from the UK Biobank. The insights provided are precise and tailored, enabling informed decisions about health management, lifestyle adjustments, and proactive preventative measures.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Step'
}
</script>

<style scoped>
.image img {
    width: 80%;
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Remove any extra space below the image */
    margin: 0 auto; /* Center the image horizontally within its container */
    padding-top: 100px;
}
</style>