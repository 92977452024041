import { auth, db } from "@/firebase";
import { sendEmailVerification } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

/*
    This file contains the functions for email verification.
    To use the functions, need to pass the context object as an argument (context is the 'this' object in Vue components).
    The context object should have the following properties:
    - interval: a variable to store the interval (if no, this will be created by the function)
    - successMessage: a variable to store the success message
    - errorMessage: a variable to store the error message
    - router_link: the route to redirect to after email verification
*/

export const startCheckingEmailVerification = (context) => {
    context.interval = setInterval(() => {
        auth.currentUser.reload()
            .then(() => {
                if (auth.currentUser.emailVerified) {
                    setDoc(doc(db, "users", auth.currentUser.uid), {
                        emailVerified: true
                    }, { merge: true });
                    clearInterval(context.interval);
                    context.successMessage = "Email verified. Redirecting...";
                    context.errorMessage = "";
                    setTimeout(() => {
                        console.log(context.router_link);
                        context.$router.push(context.router_link);
                    }, 2000);
                }
            })
            .catch((error) => {
                context.errorMessage = "An error occurred while verifying your email. Please try again.";
                context.successMessage = '';
                console.log(error);
            });
    }, 2000);
}

export const emailVerification = async (user, context) => {
    try {
        await sendEmailVerification(user);
        context.successMessage = "An email verification link has been sent to your email address. Please verify your email address.";
        context.errorMessage = "";
        context.status = 'emailVerification';
    } catch (error) {
        console.error(error);
        context.successMessage = '';
        if (error.code === 'auth/too-many-requests') {
            context.errorMessage = "Too many requests. Please try again later.";
        } else {
            context.errorMessage = "An error occurred while sending the email verification link. Please try again.";
        }
    }
    startCheckingEmailVerification(context);
}

export const emailVerificationResend = async (context) => {
    clearInterval(context.interval);
    const user = auth.currentUser;
    emailVerification(user, context);
}