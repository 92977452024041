<template>
    <div class="support-area ptb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="image">
                        <img src="../../assets/images/methy.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="support-content">
                        <h2>What is Epi-Tracker?</h2>
                        <p>Epi-Tracker combines whole genome sequencing with epigenetic profiling to dynamically monitor and predict risks for aging-related diseases. By tracking changes in DNA methylation at critical genetic variants, this powerful tool offers personalized insights into how environmental and lifestyle factors might influence disease risks. Epi-Tracker empowers users to proactively manage their health by providing a real-time view of their genetic and epigenetic landscape.</p>
                        <!-- <router-link to="/Login" class="btn btn-primary">
                            Find Out More
                        </router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Support'
    }
</script>

<style scoped>
.image img { 
    width: 100%;
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Remove any extra space below the image */
    margin: 0 auto; /* Center the image horizontally within its container */
    padding-left: 20px;
    margin-right: 80px;
}
</style>