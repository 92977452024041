<template>
    <div class="container">
        <div class="section-title ctp-title">
            <h2>We are leading globally</h2>
        </div>
        <p>We are a leading force in healthy longevity solutions on a global scale, collaborating with clients and partners from diverse regions including the UK, Japan, Singapore, and beyond. Our extensive network not only features our certified Next-Generation Sequencing (NGS) and Whole Genome Sequencing (WGS) laboratories worldwide but also encompasses a broad spectrum of global clients and collaborators. This vast reach enables us to deliver localized lab services for convenient and efficient sequencing while maintaining a global perspective in data analysis and report generation.</p>
        <br>
        <img src="../../assets/images/map_with_marker.png" alt="Global Map" class="map"/>
    </div>
</template>

<script>
    export default {
        name: 'Map'
    }
</script>

<style scoped>
.maparea {
    width: 800px;
    height:800px;
}
.map {
    width: 100%;
    height: auto;
    z-index: 1;
    /* display: block; */
}
</style>